import {
  Setting2,
  Health,
  Home3,
  Profile2User,
  Brodcast,
  Chart1,
  HeartAdd,
  UserCirlceAdd,
} from "iconsax-react";

import IntlMessages from "../../layout/components/lang/IntlMessages";

const main = [
  {
    id: "dashboards-Dashboard",
    title: <IntlMessages id="Home" />,
    icon: <Home3 size={18} />,
    navLink: "/myprofile",
  },
  {
    header: <IntlMessages id="Friends" />,
  },
  {
    id: "dashboards-friends",
    title: <IntlMessages id="My Friends" />,
    icon: <Profile2User size={18} />,
    navLink: "/network",
  },
  {
    id: "dashboards-friends",
    title: <IntlMessages id="Add Friends" />,
    icon: <UserCirlceAdd size={18} />,
    navLink: "/addnetwork",
  },
  {
    header: <IntlMessages id="Vitals" />,
  },
  {
    id: "dashboards-vitals",
    title: <IntlMessages id="Add Vitals" />,
    icon: <HeartAdd size={18} />,
    navLink: "/activities",
  },
  {
    id: "dashboards-actionplan",
    title: <IntlMessages id="Action Plan" />,
    icon: <HeartAdd size={18} />,
    navLink: "/actionplan",
  },
  {
    id: "dashboards-activities",
    title: <IntlMessages id="View Vitals" />,
    icon: <Chart1 size={18} />,
    navLink: "/activities",
    children: [
      {
        id: "dashboards-activities",
        title: <IntlMessages id="Blood Pressure" />,
        navLink: "/bloodpressure",
      },
      {
        id: "dashboards-activities",
        title: <IntlMessages id="Glucose" />,
        navLink: "/glucose",
      },
      {
        id: "dashboards-activities",
        title: <IntlMessages id="Heart Rate" />,
        navLink: "/heartrate",
      },
      {
        id: "dashboards-activities",
        title: <IntlMessages id="Oxygen Saturation" />,
        navLink: "/oxygen",
      },
      {
        id: "dashboards-activities",
        title: <IntlMessages id="Sleep" />,
        navLink: "/sleep",
      },
      {
        id: "dashboards-activities",
        title: <IntlMessages id="Weight" />,
        navLink: "/weight",
      },
    ],
  },
  {
    header: <IntlMessages id="More" />,
  },
  {
    id: "dashboards-integrations",
    title: <IntlMessages id="Integrations" />,
    icon: <Brodcast size={18} />,
    navLink: "/integrations",
    // tag: <IntlMessages id="coming-soon" />,
  },
  {
    id: "dashboards-healthreport",
    title: <IntlMessages id="Health Report" />,
    icon: <Health size={18} />,
    navLink: "/my-health-report",
  },
  {
    id: "dashboards-add-dairy",
    title: <IntlMessages id="Add Dairy" />,
    icon: <Health size={18} />,
    navLink: "/add-diary",
  },
  {
    id: "dashboards-food-journal",
    title: <IntlMessages id="Health Journal" />,
    icon: <Health size={18} />,
    navLink: "/my-food-journal-calendar",
  },
  {
    id: "dashboards-mysettings",
    title: <IntlMessages id="My Settings" />,
    icon: <Setting2 size={18} />,
    navLink: "/mysettings",
  },
];

export default main;
