import { Link } from "react-router-dom";

import { Avatar, Col, Divider, Dropdown, Row } from "antd";
import { Flag, UserOctagon } from "iconsax-react";
import { useDispatch, useSelector } from "react-redux";

import { User } from "react-iconly";
import avatarImg from "../../../assets/images/memoji/user-avatar-4.png";
import { logoutUser } from "../../../redux-toolkit/features/auth/authSlice";

export default function HeaderUser() {
  const dispatch = useDispatch();
  const { profile_data } = useSelector((state) => state?.profile);
  // const { primaryPhoto } = profile_data;
  const handleClick = () => {
    dispatch(logoutUser());
  };

  const menu = (
    <div className="hp-user-dropdown hp-border-radius hp-bg-black-0 hp-bg-dark-100 hp-border-color-dark-80 hp-py-24 hp-px-18 hp-mt-16">
      <Row>
        <Col span={24}>
          <Link
            to="/myprofile"
            className="hp-d-flex-center hp-p1-body hp-font-weight-500 hp-my-4 hp-py-8 hp-px-10 hp-d-block hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-80 hp-border-radius"
            style={{ marginLeft: -10, marginRight: -10 }}
          >
            <UserOctagon size="20" />
            <span className="hp-ml-8">View my profile</span>
          </Link>
        </Col>

        <Col span={24}>
          <Link
            to="/mysettings"
            className="hp-d-flex-center hp-p1-body hp-font-weight-500 hp-my-4 hp-py-8 hp-px-10 hp-d-block hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-80 hp-border-radius"
            style={{ marginTop: -7, marginLeft: -10, marginRight: -10 }}
          >
            <Flag size="20" />
            <span className="hp-ml-8">My Settings</span>
          </Link>
        </Col>
      </Row>

      <Divider className="hp-my-18" />

      <Row gutter={[0, 24]}>
        <Col span={24}>
          <Link
            to="/authentication/login"
            className="hp-p1-body hp-font-weight-500 hp-hover-text-color-primary-2"
            onClick={handleClick}
          >
            Log out
          </Link>
        </Col>
      </Row>
    </div>
  );

  return (
    <Col>
      <Dropdown overlay={menu} placement="bottomLeft">
        <div className="hp-border-radius-xl hp-cursor-pointer hp-border-1 hp-border-color-dark-80">
          <div
            className="hp-border-radius-lg hp-overflow-hidden hp-bg-info-4 hp-m-4 hp-d-flex"
            style={{ minWidth: 32, width: 32, height: 32 }}
          >
            <Avatar
              src={
                profile_data?.primaryPhoto !== "image.jpg"
                  ? profile_data?.primaryPhoto
                  : null
              }
              icon={<User className="remix-icon" />}
              alt="User"
              height="100%"
            />
          </div>
        </div>
      </Dropdown>
    </Col>
  );
}
