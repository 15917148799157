import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiInstance from "../../apiInstance";

const initialState = {
  isLoading: false,
  notificationsData: [],
};

export const getMyNotifications = createAsyncThunk(
  "notifications/getMyNotifications",
  async () => {
    try {
      const response = await apiInstance({
        method: "get",
        url: `/notifications/getMyNotifications`,
      });
      return response;
    } catch (error) {}
  }
);

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {},
  extraReducers: {
    [getMyNotifications.pending]: (state) => {
      state.isLoading = true;
    },
    [getMyNotifications.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.notificationsData = action?.payload?.data;
    },
    [getMyNotifications.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export default notificationsSlice.reducer;
