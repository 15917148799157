import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import apiInstance from '../../apiInstance';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const initialState = {
  isAuthenticated: false,
  isLoading: false,
  mail: '',
  forgotPasswordData: [],
  resetPasswordData: [],
  updateMyPasswordData: [],
  token: null,
};

export const registerUser = createAsyncThunk('auth/register', async (body) => {
  try {
    const response = apiInstance({
      method: 'post',
      url: '/auth/register',
      data: body,
    });
    return response;
  } catch (error) {
  }
});

export const loginUser = createAsyncThunk('auth/login', async (body) => {
  try {
    const response = await apiInstance({
      method: 'post',
      url: '/auth/login',
      data: body,
    });
    return response;
  } catch (error) {
  }
});

export const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async (body) => {
    try {
      const response = await apiInstance({
        method: 'post',
        url: '/auth/forgotPassword',
        data: body,
      });
      return response;
    } catch (error) {
    }
  }
);

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (body) => {
    try {
      const response = await apiInstance({
        method: 'post',
        url: '/auth/resetPassword',
        data: body,
      });
      return response;
    } catch (error) {
    }
  }
);

export const updateMyPassword = createAsyncThunk(
  'auth/updateMyPassword',
  async (body) => {
    try {
      const response = await apiInstance({
        method: 'post',
        url: '/auth/updateMyPassword',
        data: body,
      });
      return response;
    } catch (error) {
    }
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    addUserData(state, action) {
      state.userData = action.payload;
    },
    updateIsAuthenticated(state) {
      state.isAuthenticated = true;
    },
    logoutUser() {
      return initialState;
    },
  },
  extraReducers: {
    [registerUser.pending]: (state) => {
      state.isLoading = true;
    },
    [registerUser.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.token = action?.payload?.data?.token;
    },
    [registerUser.rejected]: (state) => {
      state.isLoading = false;
    },
    [loginUser.pending]: (state) => {
      state.isLoading = true;
    },
    [loginUser.fulfilled]: (state, action) => {
      // state.isAuthenticated = true;
      state.isLoading = false;
      state.token = action?.payload?.data?.token;
    },
    [loginUser.rejected]: (state) => {
      state.isAuthenticated = false;
      state.isLoading = false;
    },
    [forgotPassword.pending]: (state) => {
      state.isLoading = true;
    },
    [forgotPassword.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.forgotPasswordData = action?.payload?.data;
    },
    [forgotPassword.rejected]: (state) => {
      state.isAuthenticated = false;
      state.isLoading = false;
    },
    [resetPassword.pending]: (state) => {
      state.isLoading = true;
    },
    [resetPassword.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.resetPasswordData = action?.payload?.data;
    },
    [resetPassword.rejected]: (state) => {
      state.isAuthenticated = false;
      state.isLoading = false;
    },
    [updateMyPassword.pending]: (state) => {
      state.isLoading = true;
    },
    [updateMyPassword.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.updateMyPasswordData = action?.payload?.data;
    },
    [updateMyPassword.rejected]: (state) => {
      state.isAuthenticated = false;
      state.isLoading = false;
    },
  },
});

export const { addUserData, updateIsAuthenticated, logoutUser } =
  authSlice.actions;
export default authSlice.reducer;
