import React from "react";
import { useSelector } from "react-redux";
import { Row, Col } from "antd";
import bg from "../../../assets/images/graphics/authentication-bg.svg";
import bgDark from "../../../assets/images/graphics/authentication-bg-dark.svg";
import MenuLogo from '../../../layout/components/menu/logo';

const LeftContent = () => {
  const theme = useSelector(state => state.customise.theme);

  return (
    <Col
      lg={12}
      span={24}
      className="hp-p-0 hp-position-relative"
      style={{ backgroundColor: theme === 'light' ? '#F1F6FF' : '#F1F6FF' }}
    >
      <Row className="hp-image-row hp-h-100 hp-pb-md-32">
        <Col className="hp-logo-item hp-m-sm-16 hp-m-md-32 hp-m-64">
          <MenuLogo />
        </Col>
        <Col span={24}>
          <Row align="middle" justify="center" className="hp-h-100">
            <Col span={24} className="hp-bg-item hp-text-center hp-mb-md-32">
              <img src={theme === 'light' ? bg : bgDark} alt="Background Image" className="hp-w-100 hp-h-100" />
            </Col>
            <Col xl={18} span={24} className="hp-text-item hp-text-center">
              <h2 className={`hp-text-color-${theme === 'light' ? 'black-100' : 'dark-0'} hp-mx-lg-16 hp-mb-16`}>What gets measured gets managed</h2>
              <p className={`h4 hp-mb-0 hp-font-weight-400 hp-text-color-${theme === 'light' ? 'black-80' : 'dark-30'}`}>
                Manage your daily vitals and activities in one place and share it with your friends and family.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export default LeftContent;