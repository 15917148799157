import React, { useEffect, useState } from "react";
import { localizeTime } from "../../utils/date";
import moment from "moment";
import { Col, Row, Button, Tooltip, Badge } from "antd";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RiHeartAddLine, RiExternalLinkLine } from "react-icons/ri";

export default function GlucoseSampleCard({ handleGlucoseClick }) {
  const [chartWidth, setChartWidth] = useState();
  const { actionListData, readingsData } = useSelector(
    (state) => state.readings
  );
  const [glucoseData, setGlucoseData] = useState(false);
  const [options, setOptions] = useState({
    chart: {
      fontFamily: "Manrope, sans-serif",
      type: "line",

      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    stroke: {
      curve: "smooth",
      lineCap: "round",
    },
    xaxis: {
      type: "category",
      categories: [],
      tooltip: {
        enabled: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    legend: {
      show: false,
    },
    grid: {
      show: false,
      padding: {
        left: 8,
        right: 15,
        top: -10,
        bottom: -10,
      },
    },
  });
  const [series, setSeries] = useState([
    {
      name: "Glucose",
      data: [],
    },
  ]);

  const readings = [];
  const dates = [];
  const chartData = [];

  useEffect(() => {
    readingsData?.data?.glucose?.map((item) => chartData.push(item));
    const filteredBpData = chartData.filter(
      (data) => data?.readingDate !== null && data?.reading !== null
    );

    const sortedArray = _.sortBy(filteredBpData, "readingDate");

    for (const obj of sortedArray) {
      readings.push(obj?.reading);
      dates.push(
        `${localizeTime(obj?.readingDate).formattedDate} ${
          obj?.readingTime
            ? "at" + localizeTime(obj?.readingDate).formattedTime
            : ""
        } `
      );
    }
    setOptions({
      chart: {
        fontFamily: "Manrope, sans-serif",
        type: "line",

        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      xaxis: {
        type: "category",
        categories: dates,
        tooltip: {
          enabled: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
        },
      },
      yaxis: {
        show: false,
      },
      legend: {
        show: false,
      },
      grid: {
        show: false,
        padding: {
          left: 8,
          right: 15,
          top: -10,
          bottom: -10,
        },
      },
    });
    setSeries([
      {
        name: "Glucose",
        data: readings,
      },
    ]);

    const data = actionListData?.data;
    if (data) {
      var dateofvisitGlucose = moment(data?.glucose?.readingDate, "YYYY-MM-DD");
      var today = moment();
      const noOfDaysGlucose = today.diff(dateofvisitGlucose, "days").toString();
      const glucoseData = {
        integrationname: "Glucose",
        lastUpdate:
          _.isEmpty(data?.glucose) !== true
            ? `${
                data?.glucose?.readingDate
                  ? localizeTime(data?.glucose?.readingDate).formattedDate
                  : ""
              } at ${
                data?.glucose?.readingTime
                  ? localizeTime(data?.glucose?.readingTime).formattedTime
                  : ""
              }`
            : "No record",
        indicatorMessage:
          noOfDaysGlucose !== "NaN"
            ? noOfDaysGlucose >= 3
              ? "Your readings are out of date. Please update!"
              : "Your readings are up to date"
            : "Your readings are out of date. Please update!",
        indicator:
          noOfDaysGlucose !== "NaN"
            ? noOfDaysGlucose >= 3
              ? noOfDaysGlucose >= 7
                ? "error"
                : "warning"
              : "success"
            : "error",
      };
      setGlucoseData(glucoseData);
    }
  }, [readingsData, actionListData]);

  return (
    <div className="hp-border-1 hp-overflow-hidden hp-border-color-black-40 hp-border-color-dark-80 hp-border-radius-xxl hp-bg-color-black-0 hp-bg-color-dark-100 hp-p-16 hp-card-2">
      <Row gutter={16} align="middle">
        <Col span={24} className="hp-text-left">
          <span className="h4 hp-font-weight-800 hp-text-color-black-bg hp-text-color-dark-0 hp-d-block">
            <Link
              to="/glucose"
              className="h4 hp-font-weight-800 hp-text-color-black-bg hp-text-color-dark-0 hp-d-block"
            >
              Glucose
            </Link>
          </span>
          {glucoseData !== false ? (
            <p style={{ fontSize: "12px" }} className="hp-p1-body hp-mb-0">
              {" "}
              <Tooltip title={glucoseData.indicatorMessage}>
                <Badge status={glucoseData.indicator} />
              </Tooltip>{" "}
              Last reading {glucoseData.lastUpdate}
            </p>
          ) : null}

          <div
            id="chart"
            className="hp-bg-color-success-4 hp-bg-color-dark-90 hp-border-radius-xl hp-d-flex-center hp-mb-18"
          >
            <Chart
              options={options}
              series={series}
              width={chartWidth}
              height={135}
            />
          </div>

          <h3
            className="hp-mb-0 hp-font-weight-600 hp-d-flex-full-center"
            style={{ display: "flex", gap: "5px" }}
          >
            <span>{actionListData?.data?.glucose?.reading}</span>
            <p className="hp-p1-body hp-mb-0">mg/dL</p>
          </h3>
        </Col>
      </Row>
      <Row className="text-center hp-mt-8">
        <Col xs={12} className="mb-3">
          <Button size="small" type="text" className="hp-hover-bg-primary-4">
            <RiExternalLinkLine className="remix-icon" />
            <Link
              to="/glucose"
              className="h6 hp-font-weight-500 hp-text-color-black-bg hp-text-color-dark-0 hp-d-block"
            >
              View More
            </Link>
          </Button>
        </Col>
        <Col xs={12} className="mb-3">
          <Button
            size="small"
            onClick={(e) => {
              e.preventDefault();
              handleGlucoseClick();
            }}
            type="text"
            className="hp-hover-bg-primary-4"
          >
            <RiHeartAddLine className="remix-icon" />
            Add Entry
          </Button>
        </Col>
      </Row>
    </div>
  );
}
