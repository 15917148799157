import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiInstance from "../../apiInstance";

const initialState = {
  isLoading: false,
  isRequestFailed: false,
  send_request_data: [],
  my_sent_requests: [],
  friend_requests: [],
  acceptRequest: [],
  removeFriendData: [],
  cancelRequestData: [],
  changeRelationData: [],
};

export const sendFriendRequest = createAsyncThunk(
  "network/sendFriendRequest",
  async (body) => {
    try {
      const response = await apiInstance({
        method: "post",
        url: "/network/sendFriendRequest",
        data: body,
      });
      return response;
    } catch (error) {}
  }
);

export const mySentFriendRequests = createAsyncThunk(
  "network/mySentFriendRequests",
  async () => {
    try {
      const response = await apiInstance({
        method: "get",
        url: "/network/sentRequests",
      });
      return response;
    } catch (error) {}
  }
);

export const friendRequests = createAsyncThunk(
  "network/friendRequests",
  async () => {
    try {
      const response = await apiInstance({
        method: "get",
        url: "/network/friendRequests",
      });
      return response;
    } catch (error) {}
  }
);

export const acceptOrRejectRequest = createAsyncThunk(
  "network/acceptOrRejectRequest",
  async (body) => {
    try {
      const response = await apiInstance({
        method: "post",
        url: "/network/acceptOrRejectRequest",
        data: body,
      });
      return response;
    } catch (error) {}
  }
);
export const acceptOrRejectRequestFromNotification = createAsyncThunk(
  "network/acceptOrRejectRequestFromNotification",
  async (body) => {
    try {
      const response = await apiInstance({
        method: "post",
        url: "/network/acceptOrRejectRequestFromNotification",
        data: body,
      });
      return response;
    } catch (error) {}
  }
);

export const removeFriend = createAsyncThunk(
  "network/removeFriend",
  async (body) => {
    try {
      const response = await apiInstance({
        method: "post",
        url: "/network/removeFriend",
        data: body,
      });
      return response;
    } catch (error) {}
  }
);

export const cancelRequest = createAsyncThunk(
  "network/cancelFriendRequest",
  async (body) => {
    try {
      const response = await apiInstance({
        method: "post",
        url: "/network/cancelFriendRequest",
        data: body,
      });
      return response;
    } catch (error) {}
  }
);

export const changeRelationType = createAsyncThunk(
  "network/changeRelationType",
  async (body) => {
    try {
      const response = await apiInstance({
        method: "post",
        url: "/network/changeRelationType",
        data: body,
      });
      return response;
    } catch (error) {}
  }
);

export const networkSlice = createSlice({
  name: "network",
  initialState,
  reducers: {},
  extraReducers: {
    [sendFriendRequest.pending]: (state) => {
      state.isLoading = true;
    },
    [sendFriendRequest.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.send_request_data = action?.payload?.data;
    },
    [sendFriendRequest.rejected]: (state) => {
      console.log("it is rejected");
      state.isLoading = false;
    },
    [mySentFriendRequests.pending]: (state) => {
      state.isLoading = true;
    },
    [mySentFriendRequests.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.my_sent_requests = action?.payload?.data;
    },
    [mySentFriendRequests.rejected]: (state) => {
      state.isLoading = false;
    },
    [friendRequests.pending]: (state) => {
      state.isLoading = true;
    },
    [friendRequests.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.friend_requests = action?.payload?.data;
    },
    [friendRequests.rejected]: (state) => {
      state.isLoading = false;
    },
    [acceptOrRejectRequest.pending]: (state) => {
      state.isLoading = true;
    },
    [acceptOrRejectRequest.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.acceptRequest = action?.payload?.data;
    },
    [acceptOrRejectRequest.rejected]: (state) => {
      state.isLoading = false;
    },
    [removeFriend.pending]: (state) => {
      state.isLoading = true;
    },
    [removeFriend.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.removeFriendData = action?.payload?.data;
    },
    [removeFriend.rejected]: (state) => {
      state.isLoading = false;
    },
    [cancelRequest.pending]: (state) => {
      state.isLoading = true;
    },
    [cancelRequest.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.cancelRequestData = action?.payload?.data;
    },
    [cancelRequest.rejected]: (state) => {
      state.isLoading = false;
    },
    [changeRelationType.pending]: (state) => {
      state.isLoading = true;
    },
    [changeRelationType.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.changeRelationData = action?.payload?.data;
    },
    [changeRelationType.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export default networkSlice.reducer;
