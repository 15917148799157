import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  Avatar,
  Badge,
  Button,
  Col,
  Divider,
  Dropdown,
  Row,
  notification,
} from "antd";
import { Link } from "react-router-dom";
import { NotificationBing } from "iconsax-react";

import moment from "moment";
import avatarImg1 from "../../../assets/images/memoji/user-avatar-1.png";
import { getUsersProfile } from "../../../redux-toolkit/features/users/usersSlice";

import { useHistory } from "react-router-dom";
import { acceptOrRejectRequestFromNotification } from "../../../redux-toolkit/features/network/networkSlice";
import {
  RiCheckboxCircleFill,
  RiCloseFill,
  RiErrorWarningFill,
} from "react-icons/ri";
import { localizeTime } from "../../../utils/date";
import { getMyNotifications } from "../../../redux-toolkit/features/notifications/notificationsSlice";
export default function HeaderNotifications() {
  const dispatch = useDispatch();
  const history = useHistory();

  const direction = useSelector((state) => state.customise.direction);
  const { notificationsData } = useSelector((state) => state.notifications);
  const [unreadNotifications, setUnReadNotifications] = useState(0);
  useEffect(() => {
    const unRead = notificationsData?.data?.filter(
      (item) => item.isRead === false
    );

    setUnReadNotifications(unRead?.length || 0);
  }, [notificationsData]);
  useEffect(() => {
    dispatch(getMyNotifications());
  }, []);
  const openSuccessNotification = (name) => {
    notification.open({
      message: `You successfully added "${name}" to your network.`,
      icon: <RiCheckboxCircleFill style={{ color: "#00F7BF" }} />,
      closeIcon: (
        <RiCloseFill className="remix-icon hp-text-color-black-80" size={24} />
      ),
    });
  };

  const openInfoNotification = (name) => {
    notification.open({
      message: `You rejected "${name}" request to join your network.`,
      icon: <RiErrorWarningFill style={{ color: "#1BE7FF" }} />,
      closeIcon: (
        <RiCloseFill className="remix-icon hp-text-color-black-80" size={24} />
      ),
    });
  };
  const handlePicClick = (id) => {
    dispatch(getUsersProfile(id)).then(() => {
      history.push("/profile");
    });
  };
  const handleClick = (e) => {
    let request = {
      notificationId: e?.currentTarget?.id,
      type: e?.currentTarget?.name,
      name: e?.currentTarget?.value,
    };
    console.log({ request });

    request?.type === "accept"
      ? openSuccessNotification(e?.currentTarget?.value)
      : openInfoNotification(e?.currentTarget?.value);

    dispatch(acceptOrRejectRequestFromNotification(request)).then((data) => {
      console.log({ data });
      window.location.reload();
      // dispatch(getProfileData());
      // dispatch(friendRequests());
    });
  };
  const handleShowNotificationButtons = (data) => {
    if (data?.isFriendRequest) {
      return (
        <p>
          <Button
            value={data?.from?.firstName + " " + data?.from?.lastName}
            id={data?._id}
            type="primary"
            style={{ marginRight: "10px" }}
            name="accept"
            onClick={(e) => handleClick(e)}
          >
            Accept
          </Button>
          <Button
            name="reject"
            value={data?.from?.firstName + " " + data?.from?.lastName}
            id={data?._id}
            type="danger"
            onClick={(e) => handleClick(e)}
          >
            Reject
          </Button>
        </p>
      );
    }

    if (data?.postDetails?.isLikedOrCommented === true) {
      //lets show the view details button
      return (
        <p>
          <Link
            // value={data?.from?.firstName + " " + data?.from?.lastName}
            // id={data?._id}
            to={`/post-details/${data?.postDetails?.post}?notificationId=${data.id}`}
            // type="primary"
            style={{ marginRight: "10px" }}
            // name="accept"
            // onClick={(e) => handleClick(e)}
          >
            View Details
          </Link>
        </p>
      );
    }
  };

  const notificationMenu = (
    <div
      className="hp-notification-dropdown hp-border-radius hp-border-color-black-40 hp-bg-black-0 hp-bg-dark-100 hp-border-color-dark-80 hp-pt-24 hp-pb-18 hp-px-24"
      style={{ marginTop: 23 }}
    >
      <Row
        wrap={false}
        align="middle"
        justify="space-between"
        className="hp-mb-16"
      >
        <Col className="h5 hp-text-color-black-100 hp-text-color-dark-0 hp-mr-64">
          Notifications
        </Col>

        <Col className="hp-badge-text hp-font-weight-500 hp-text-color-black-80 hp-ml-24">
          {unreadNotifications} new
        </Col>
      </Row>

      <Divider className="hp-mt-0 hp-mb-4" />

      <div
        className="hp-overflow-y-auto hp-px-10"
        style={{ maxHeight: 400, marginRight: -10, marginLeft: -10 }}
      >
        {notificationsData?.data?.map((data) => (
          <Row
            className={`${
              data.isRead === false ? "hp-bg-primary-4" : ""
            } hp-cursor-pointer hp-border-radius hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-80 hp-py-12 hp-px-10`}
            style={{ marginLeft: -10, marginRight: -10 }}
          >
            <Col className="hp-mr-12">
              <Avatar
                size={48}
                onClick={(e) => {
                  e.preventDefault();
                  handlePicClick(data.from.id);
                }}
                src={data?.from?.primaryPhoto}
                className="hp-d-flex-center-full"
              />
            </Col>

            <Col flex="1 0 0">
              <p
                onClick={(e) => {
                  console.log({ data });
                }}
                className="hp-d-block hp-font-weight-500 hp-p1-body hp-text-color-black-100 hp-text-color-dark-0 hp-mb-4"
              >
                {data?.text}
                {/* {data?.isFriendRequest === true ? (
                  <p>
                    <Button
                      value={data?.from?.firstName + " " + data?.from?.lastName}
                      id={data?._id}
                      type="primary"
                      style={{ marginRight: "10px" }}
                      name="accept"
                      onClick={(e) => handleClick(e)}
                    >
                      Accept
                    </Button>
                    <Button
                      name="reject"
                      value={data?.from?.firstName + " " + data?.from?.lastName}
                      id={data?._id}
                      type="danger"
                      onClick={(e) => handleClick(e)}
                    >
                      Reject
                    </Button>
                  </p>
                ) : null} */}
                {handleShowNotificationButtons(data)}
              </p>

              <span className="hp-d-block hp-badge-text hp-font-weight-500 hp-text-color-black-60 hp-text-color-dark-40">
                {localizeTime(data?.createdAt).formattedTime}
              </span>
            </Col>
          </Row>
        ))}
      </div>
    </div>
  );

  return (
    <Col className="hp-d-flex-center">
      <Button
        ghost
        type="primary"
        className="hp-border-none hp-hover-bg-black-10 hp-hover-bg-dark-100"
        icon={
          <Dropdown overlay={notificationMenu} placement="bottomRight">
            <div className="hp-position-relative">
              <div
                className="hp-position-absolute"
                style={
                  direction == "rtl"
                    ? { left: -5, top: -5 }
                    : { right: -5, top: -5 }
                }
              >
                <Badge dot status="processing" />
              </div>

              <NotificationBing
                size="22"
                className="hp-text-color-black-80 hp-text-color-dark-30"
              />
            </div>
          </Dropdown>
        }
      />
    </Col>
  );
}
