import { Link } from "react-router-dom";

import { useSelector } from "react-redux";

import newLogoDark from "../../../../assets/images/logo/Aoide-logo-light.svg";
import newLogoFullDark from "../../../../assets/images/logo/aoide-logo-full-dark.svg";
import newLogoFullLight from "../../../../assets/images/logo/Aoide-logo-full-light.svg";
import newLogoLight from "../../../../assets/images/logo/Aoide-logo-light.svg";
import logoDark from "../../../../assets/images/logo/logo-dark.svg";
import logoRTLDark from "../../../../assets/images/logo/logo-rtl-dark.svg";
import logoRTL from "../../../../assets/images/logo/logo-rtl.svg";
import logoSmallDark from "../../../../assets/images/logo/logo-small-dark.svg";
import logoSmall from "../../../../assets/images/logo/logo-small.svg";
import logo from "../../../../assets/images/logo/logo.svg";




import themeConfig from '../../../../configs/themeConfig.jsx';

export default function MenuLogo(props) {
  const customise = useSelector(state => state.customise)

  return (
    <div className="hp-header-logo hp-d-flex hp-align-items-center">
      <Link
        to="/myprofile"
        onClick={props.onClose}
        className="hp-position-relative hp-d-flex"
      >
        {
          props.small ? (
            customise.theme == "light" ? (
              <img className="hp-logo" src={newLogoDark} alt="logo" />
            ) : (
              <img className="hp-logo" src={newLogoFullDark} alt="logo" />
            )
          ) : (
            customise.direction == "rtl" ? (
              customise.theme == "light" ? (
                <img className="hp-logo" src={logoRTL} alt="logo" />
              ) : (
                <img className="hp-logo" src={newLogoFullDark} alt="logo" />
              )
            ) : (
              customise.theme == "light" ? (
                <img className="hp-logo" src={newLogoFullLight} alt="logo" />
              ) : (
                <img className="hp-logo" src={newLogoFullDark} alt="logo" />
              )
            )
          )
        }

      </Link>

    </div>
  );
};