import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import apiInstance from '../../apiInstance';

const initialState = {
  isLoading: false,
  weight_data: [],
  o2Level_data: [],
  heartRate_data: [],
  bloodPressure_data: [],
  glucose_data: [],
  sleepTiming_data: [],
  readingsData: [],
  tableData: [],
  actionListData: [],
  friendDataTableData: [],
  friendsActionListData: [],
  friendsMiniStatsData: []
};


export const weight = createAsyncThunk('readings/new/weight', async (body) => {
  try {
    const response = await apiInstance({
      method: 'post',
      url: '/readings/new/weight',
      data: body,
    });
    return response;
  } catch (error) { }
});

export const o2Level = createAsyncThunk(
  'readings/new/o2Level',
  async (body) => {
    try {
      const response = await apiInstance({
        method: 'post',
        url: '/readings/new/o2Level',
        data: body,
      });
      return response;
    } catch (error) { }
  }
);

export const heartRate = createAsyncThunk(
  'readings/new/heartRate',
  async (body) => {
    try {
      const response = await apiInstance({
        method: 'post',
        url: '/readings/new/heartRate',
        data: body,
      });
      return response;
    } catch (error) { }
  }
);

export const bloodPressure = createAsyncThunk(
  'readings/new/bloodPressure',
  async (body) => {
    try {
      const response = await apiInstance({
        method: 'post',
        url: '/readings/new/bloodPressure',
        data: body,
      });
      return response;
    } catch (error) { }
  }
);

export const glucose = createAsyncThunk(
  'readings/new/glucose',
  async (body) => {
    try {
      const response = await apiInstance({
        method: 'post',
        url: '/readings/new/glucose',
        data: body,
      });
      return response;
    } catch (error) { }
  }
);

export const sleepTiming = createAsyncThunk(
  'readings/new/sleepTiming',
  async (body) => {
    try {
      const response = await apiInstance({
        method: 'post',
        url: '/readings/new/sleepTiming',
        data: body,
      });
      return response;
    } catch (error) { }
  }
);

export const miniStats = createAsyncThunk(
  'readings/stats/miniStats',
  async () => {
    try {
      const response = await apiInstance({
        method: 'get',
        url: '/readings/stats/miniStats',
      });
      return response;
    } catch (error) { }
  }
);

export const dataTables = createAsyncThunk(
  'readings/dataTables/getData',
  async (activity) => {
    try {
      const response = await apiInstance({
        method: 'get',
        url: `/readings/dataTables/getData?acitivityType=${activity?.activity}&startDate=${activity?.startDate}&endDate=${activity?.endDate}`,
      });
      return response;
    } catch (error) { }
  }
);

export const actionList = createAsyncThunk(
  'readings/actionList',
  async () => {
    try {
      const response = await apiInstance({
        method: 'get',
        url: `/readings/actionList`,
      });
      return response;
    } catch (error) { }
  }
);

export const friendsDataTables = createAsyncThunk(
  'readings/dataTables/friends/getData',
  async (activity) => {
    try {
      const response = await apiInstance({
        method: 'get',
        url: `/readings/dataTables/friends/getData?acitivityType=${activity?.activity}&startDate=${activity?.startDate}&endDate=${activity?.endDate}&userId=${activity?.userId}`,
      });
      return response;
    } catch (error) { }
  }
);

export const friendsActionList = createAsyncThunk(
  'readings/friends/actionList',
  async (id) => {
    try {
      const response = await apiInstance({
        method: 'get',
        url: `/readings/friends/actionList?userId=${id}`,
      });
      return response;
    } catch (error) { }
  }
);

export const friendsMiniStats = createAsyncThunk(
  'readings/friend/stats/miniStats',
  async (id) => {
    try {
      const response = await apiInstance({
        method: 'get',
        url: `/readings/friend/stats/miniStats?userId=${id}`,
      });
      return response;
    } catch (error) { }
  }
);

export const deleteActivity = createAsyncThunk(
  'readings/deleteActivity',
  async (body) => {
    try {
      const response = await apiInstance({
        method: 'post',
        url: '/readings/deleteActivity',
        data: body,
      });
      return response;
    } catch (error) { }
  }
);

export const readingSlice = createSlice({
  name: 'readings',
  initialState,
  reducers: {},
  extraReducers: {
    [weight.pending]: (state) => {
      state.isLoading = true;
    },
    [weight.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.weight_data = action?.payload?.data;
    },
    [weight.rejected]: (state) => {
      state.isLoading = false;
    },
    [o2Level.pending]: (state) => {
      state.isLoading = true;
    },
    [o2Level.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.o2Level_data = action?.payload?.data;
    },
    [o2Level.rejected]: (state) => {
      state.isLoading = false;
    },
    [heartRate.pending]: (state) => {
      state.isLoading = true;
    },
    [heartRate.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.heartRate_data = action?.payload?.data;
    },
    [heartRate.rejected]: (state) => {
      state.isLoading = false;
    },
    [bloodPressure.pending]: (state) => {
      state.isLoading = true;
    },
    [bloodPressure.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.bloodPressure_data = action?.payload?.data;
    },
    [bloodPressure.rejected]: (state) => {
      state.isLoading = false;
    },
    [glucose.pending]: (state) => {
      state.isLoading = true;
    },
    [glucose.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.glucose_data = action?.payload?.data;
    },
    [glucose.rejected]: (state) => {
      state.isLoading = false;
    },
    [sleepTiming.pending]: (state) => {
      state.isLoading = true;
    },
    [sleepTiming.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.sleepTiming_data = action?.payload?.data;
    },
    [sleepTiming.rejected]: (state) => {
      state.isLoading = false;
    },
    [miniStats.pending]: (state) => {
      state.isLoading = true;
    },
    [miniStats.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.readingsData = action?.payload?.data;
    },
    [miniStats.rejected]: (state) => {
      state.isLoading = false;
    },
    [dataTables.pending]: (state) => {
      state.isLoading = true;
    },
    [dataTables.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.tableData = action?.payload?.data;
    },
    [dataTables.rejected]: (state) => {
      state.isLoading = false;
    },
    [actionList.pending]: (state) => {
      state.isLoading = true;
    },
    [actionList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.actionListData = action?.payload?.data;
    },
    [actionList.rejected]: (state) => {
      state.isLoading = false;
    },
    [friendsDataTables.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.friendDataTableData = action?.payload?.data;
    },
    [friendsDataTables.rejected]: (state) => {
      state.isLoading = false;
    },
    [friendsActionList.pending]: (state) => {
      state.isLoading = true;
    },
    [friendsActionList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.friendsActionListData = action?.payload?.data;
    },
    [friendsActionList.rejected]: (state) => {
      state.isLoading = false;
    },
    [friendsMiniStats.pending]: (state) => {
      state.isLoading = true;
    },
    [friendsMiniStats.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.friendsMiniStatsData = action?.payload?.data;
    },
    [friendsMiniStats.rejected]: (state) => {
      state.isLoading = false;
    },
    [deleteActivity.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [deleteActivity.rejected]: (state) => {
      state.isLoading = true;
    },

  },
});

export default readingSlice.reducer;
