import React from 'react'

import { Col, Row } from 'antd'

export default function Footer() {
  return (
    <Col span={24} className="hp-py-32">
      <p className="hp-mb-8 hp-p1-body hp-text-color-black-60">
        COPYRIGHT ©2024 Aoide Health, All rights Reserved
      </p>

      <Row align="middle" justify="center" gutter="16">
      <Col>
                    <a href="https://www.aoidehealth.com/privacy-policy/" className="hp-text-color-black-80 hp-text-color-dark-40">
                        Privacy Policy
                    </a>
                </Col>
                <Col>
                    <a href="https://www.aoidehealth.com/data-policy/" className="hp-text-color-black-80 hp-text-color-dark-40">
                        Data Policy
                    </a>
                </Col>

                <Col>
                    <a href="https://www.aoidehealth.com/terms-of-service/" className="hp-text-color-black-80 hp-text-color-dark-40">
                        Term of use
                    </a>
                </Col>
      </Row>
    </Col>
  )
}
