import React from 'react'

import { Col, Row } from 'antd'

export default function Footer() {
    return (
        <Col className="hp-mt-sm-48 hp-mt-96">
            <Row align="middle" justify="center" gutter={[16]}>
                <Col>
                <a href="https://www.aoidehealth.com/privacy-policy" target="_blank" rel="noopener noreferrer" className="hp-badge-text hp-font-weight-400 hp-mb-0 hp-text-color-dark-30">
                     Privacy Policy
                </a>
                </Col>
                <Col>
                <a href="https://www.aoidehealth.com/data-policy" target="_blank" rel="noopener noreferrer" className="hp-badge-text hp-font-weight-400 hp-mb-0 hp-text-color-dark-30">
    Data Policy
</a>
                </Col>


                <Col>
                <a href="https://www.aoidehealth.com/terms-of-use" target="_blank" rel="noopener noreferrer" className="hp-badge-text hp-font-weight-400 hp-mb-0 hp-text-color-dark-30">
    Terms of Use
</a>

                </Col>
                <p className="hp-p1-body hp-text-center hp-text-color-black-60 hp-mb-8">COPYRIGHT © 2023, All rights Reserved. Aoide Health</p>

            </Row>
        </Col>
    )
}
