import React from "react";

import { Card, Row, Col, Badge, Button } from "antd";
import { RiArrowRightLine, RiRefreshLine } from "react-icons/ri";



import chrome from "../../assets/images/browser/chrome.svg";


export default function IntegoverviewCard() {
  const statesArr = [
    {
      integrationlogo: <img src={chrome} alt="Google Chrome" />,
      integrationname: "Google Fit",
      integrationstatus: "Connected",
      integrationslasttimestamp: "8:24 pm"
    },
  ];


  
  const renderStates = () => {
    return statesArr.map((state) => {
      return (
        <div key={state.integrationname}>
          <Row justify="space-between" className="hp-mt-12">
            <Col span={12}>
              <Row>
                <div className="hp-mr-8">{state.integrationlogo}</div>
                <p className="hp-p1-body hp-mb-0">{state.integrationname}</p>
              </Row>
            </Col>
            
            <Col className="hp-text-right" span={12}>
              
              <Row align="end">
              <Badge status="success" />

                <p className="hp-badge-text hp-mb-0 hp-mr-8 hp-align-self-center">
                  {state.integrationstatus}
                </p>
                <p className="hp-badge-text hp-mb-0 hp-mr-8 hp-align-self-center">
                  {state.integrationslasttimestamp}
                </p>
                <Button className="hp-float-right" size="small" type="text">
            {<RiArrowRightLine className="remix-icon" />}
            </Button>             
             
             
             
             
             
              </Row>
            </Col>
          </Row>
        </div>
      );
    });
  };

  return (
    <Card className="hp-border-color-black-40 hp-mb-32 hp-card-3 hp-eCommerceCardOne">
      <Row>
        <Col span={24} className="hp-mb-8">
          <Row justify="space-between" align="middle">
          <span className="h3 hp-font-weight-600 hp-text-color-black-bg hp-text-color-dark-0 hp-d-block">Integrations Overview</span>
            <p className="hp-badge-text hp-text-color-black-80 hp-text-color-dark-30 hp-mb-0">
              Status
            </p>
            <Button className="hp-float-right" size="small" type="text">
            {<RiRefreshLine className="remix-icon" />}
            </Button>            </Row>
        </Col>

        <Col span={24} className="hp-mb-4">
          {renderStates()}
        </Col>
      </Row>
    </Card>
  );
}
