import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import apiInstance from '../../apiInstance';

const initialState = {
  isLoading: false,
  users_data: [],
};

export const searchUsers = createAsyncThunk('search/users', async (search) => {
  try {
    const response = await apiInstance({
      method: 'get',
      url: `/search/users?search=${search}`,
    });
    return response;
  } catch (error) {}
});

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {},
  extraReducers: {
    [searchUsers.pending]: (state) => {
      state.isLoading = true;
    },
    [searchUsers.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.users_data = action?.payload?.data;
    },
    [searchUsers.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export default searchSlice.reducer;
