import { Col, Layout, Row } from "antd";

export default function MenuFooter() {
  const { Footer } = Layout;

  const footerStyle = {
    marginTop: "50px", // Add 50-pixel margin to the top
  };

  const linkStyle = {
    marginRight: "10px", // Add margin between links
  };

  return (
    <Footer className="hp-bg-color-black-20 hp-bg-color-dark-90" style={footerStyle}>
<Row align="middle" justify="space-between">
      <Col md={24} span={24}>
      <p className="hp-badge-text hp-font-weight-400 hp-mb-0 hp-text-color-dark-30">
      Disclaimer: This application is designed for the purpose of recording and organizing data that can be shared with family, caregivers, and healthcare providers. It is important to note that this app is not intended for diagnosing or treating illnesses or emergency conditions. Always seek professional medical advice and attention for any health concerns or emergencies. The information provided through this app should not be considered a substitute for medical consultation. Use this application responsibly and in conjunction with proper medical care.
      </p>
      </Col>
   </Row>
      <Row align="middle" justify="space-between">
        <Col>
          <a href="https://www.aoidehealth.com/privacy-policy/" className="hp-badge-text hp-font-weight-400 hp-mb-0 hp-text-color-dark-30" style={linkStyle}>
            Privacy Policy
          </a>
          <a href="https://www.aoidehealth.com/data-policy/" className="hp-badge-text hp-font-weight-400 hp-mb-0 hp-text-color-dark-30" style={linkStyle}>
            Data Policy
          </a>
          <a href="https://www.aoidehealth.com/terms-of-service/" className="hp-badge-text hp-font-weight-400 hp-mb-0 hp-text-color-dark-30" style={linkStyle}>
            Terms of Use
          </a>
        </Col>
      </Row>
      <Row align="middle" justify="space-between">
        <Col md={12} span={24}>
          <p className="hp-badge-text hp-font-weight-400 hp-mb-0 hp-text-color-dark-30">
            COPYRIGHT © 2024, All rights Reserved. Aoide Health
          </p>
        </Col>
      </Row>
    </Footer>
  );
};
