import { lazy } from "react";

export const PublicRoutes = [
  {
    path: "/authentication/invite",
    component: lazy(() => import("../../view/pages/authentication/invite")),
    layout: "FullLayout",
  },
  {
    path: "/authentication/login",
    component: lazy(() => import("../../view/pages/authentication/login")),
    layout: "FullLayout",
  },
  {
    path: "/authentication/recover-password",
    component: lazy(() =>
      import("../../view/pages/authentication/recover-password")
    ),
    layout: "FullLayout",
  },
  {
    path: "/authentication-modern/register",
    component: lazy(() =>
      import("../../view/pages/authentication-modern/register")
    ),
    layout: "FullLayout",
  },
  {
    path: "/authentication/confirm-email/:token",
    component: lazy(() => import("../../view/pages/confirm/index")),
    layout: "FullLayout",
  },
  {
    path: "/authentication/register",
    component: lazy(() =>
      import("../../view/pages/authentication-modern/register")
    ),
    layout: "FullLayout",
  },
  {
    path: "/authentication/reset-password",
    component: lazy(() =>
      import("../../view/pages/authentication/reset-password")
    ),
    layout: "FullLayout",
  },
  {
    path: "/error-403",
    component: lazy(() => import("../../view/pages/errors/403")),
    layout: "FullLayout",
  },
  {
    path: "/error-404",
    component: lazy(() => import("../../view/pages/errors/404")),
    layout: "FullLayout",
  },
  {
    path: "/error-500",
    component: lazy(() => import("../../view/pages/errors/500")),
    layout: "FullLayout",
  },
  {
    path: "/error-502",
    component: lazy(() => import("../../view/pages/errors/502")),
    layout: "FullLayout",
  },
  {
    path: "/error-503",
    component: lazy(() => import("../../view/pages/errors/503")),
    layout: "FullLayout",
  },

  {
    path: "/coming-soon",
    component: lazy(() => import("../../view/pages/errors/coming-soon")),
    layout: "FullLayout",
  },
  {
    path: "/maintenance",
    component: lazy(() => import("../../view/pages/errors/maintenance")),
    layout: "FullLayout",
  },
];

export const PrivateRoutes = [
  // PAGES

  {
    path: "/authentication/createprofile",
    component: lazy(() =>
      import("../../view/pages/authentication/createprofile")
    ),
    layout: "FullLayout",
  },
  {
    path: "/user-onboarding",
    component: lazy(() =>
      import("../../view/pages/authentication-modern/onboarding")
    ),
    layout: "FullLayout",
  },
  {
    path: "/my-health-report",
    component: lazy(() => import("../../view/pages/health-report/view-report")),
    layout: "VerticalLayout",
  },

  {
    path: "/network",
    component: lazy(() => import("../../view/pages/network")),
    layout: "VerticalLayout",
  },
  {
    path: "/mysettings",
    component: lazy(() => import("../../view/pages/mysettings")),
    layout: "VerticalLayout",
  },

  {
    path: "/addnetwork",
    component: lazy(() => import("../../view/pages/addnetwork")),
    layout: "VerticalLayout",
  },

  {
    path: "/integrations",
    component: lazy(() => import("../../view/pages/integrations")),
    layout: "VerticalLayout",
  },
  {
    path: "/activities",
    component: lazy(() => import("../../view/pages/activities")),
    layout: "VerticalLayout",
  },
  {
    path: "/actionplan",
    component: lazy(() => import("../../view/pages/actionplan")),
    layout: "VerticalLayout",
  },
  {
    path: "/getstarted",
    component: lazy(() => import("../../view/pages/getstarted")),
    layout: "VerticalLayout",
  },
  {
    path: "/termsofuse",
    component: lazy(() => import("../../view/pages/termsofuse")),
    layout: "VerticalLayout",
  },
  {
    path: "/privacypolicy",
    component: lazy(() => import("../../view/pages/privacypolicy")),
    layout: "VerticalLayout",
  },
  {
    path: "/feed",
    component: lazy(() => import("../../view/pages/feed")),
    layout: "VerticalLayout",
  },
  {
    path: "/dashboard",
    component: lazy(() => import("../../view/pages/dashboard")),
    layout: "VerticalLayout",
  },
  {
    path: "/profile",
    component: lazy(() => import("../../view/pages/profile")),
    layout: "VerticalLayout",
  },
  {
    path: "/myprofile",
    component: lazy(() => import("../../view/pages/myprofile")),
    layout: "VerticalLayout",
  },
  {
    path: "/health-report",
    component: lazy(() => import("../../view/pages/health-report")),
    layout: "VerticalLayout",
  },
  {
    path: "/add-diary",
    component: lazy(() => import("../../view/pages/diary-journal")),
    layout: "VerticalLayout",
  },
  {
    path: "/activities-by-day/:b64",
    component: lazy(() =>
      import("../../view/pages/diary-journal/Single-day-calendar")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/my-food-journal-calendar",
    component: lazy(() =>
      import("../../view/pages/diary-journal/food-calendar")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/aoide-gpt",
    component: lazy(() => import("../../view/pages/gpt/index")),
    layout: "VerticalLayout",
  },
  {
    path: "/post-details/:postId",
    component: lazy(() => import("../../view/pages/post-details/index")),
    layout: "VerticalLayout",
  },
  {
    path: "/announcements",
    component: lazy(() =>
      import("../../view/pages/all-announcements-page/index")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/bloodpressure",
    component: lazy(() => import("../../view/pages/bloodpressure")),
    layout: "VerticalLayout",
  },
  {
    path: "/heartrate",
    component: lazy(() => import("../../view/pages/heartrate")),
    layout: "VerticalLayout",
  },
  {
    path: "/oxygen",
    component: lazy(() => import("../../view/pages/oxygen")),
    layout: "VerticalLayout",
  },
  {
    path: "/glucose",
    component: lazy(() => import("../../view/pages/glucose")),
    layout: "VerticalLayout",
  },
  {
    path: "/sleep",
    component: lazy(() => import("../../view/pages/sleep")),
    layout: "VerticalLayout",
  },
  {
    path: "/weight",
    component: lazy(() => import("../../view/pages/weight")),
    layout: "VerticalLayout",
  },

  {
    path: "/invoice",
    component: lazy(() => import("../../view/pages/invoice")),
    layout: "VerticalLayout",
  },
  {
    path: "/knowledge-base/knowledge-base-1",
    component: lazy(() =>
      import("../../view/pages/knowledge-base/knowledge-base-1")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/knowledge-base/knowledge-base-2",
    component: lazy(() =>
      import("../../view/pages/knowledge-base/knowledge-base-2")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/welcome",
    component: lazy(() => import("../../view/pages/lock-screen/welcome")),
    layout: "FullLayout",
  },
  {
    path: "/password-is-changed",
    component: lazy(() =>
      import("../../view/pages/lock-screen/password-is-changed")
    ),
    layout: "FullLayout",
  },
  {
    path: "/deactivated",
    component: lazy(() => import("../../view/pages/lock-screen/deactivated")),
    layout: "FullLayout",
  },
  {
    path: "/lock",
    component: lazy(() => import("../../view/pages/lock-screen/lock")),
    layout: "FullLayout",
  },
  {
    path: "/pricing",
    component: lazy(() => import("../../view/pages/pricing")),
    layout: "VerticalLayout",
  },
  {
    path: "/ProfileBloodpressure",
    component: lazy(() => import("../../view/pages/friendsBloodpressure")),
    layout: "VerticalLayout",
  },
  {
    path: "/ProfileGlucose",
    component: lazy(() => import("../../view/pages/friendsGlucose")),
    layout: "VerticalLayout",
  },
  {
    path: "/ProfileHeartRate",
    component: lazy(() => import("../../view/pages/friendsHeartRate")),
    layout: "VerticalLayout",
  },
  {
    path: "/ProfileOxygen",
    component: lazy(() => import("../../view/pages/friendsOxygen")),
    layout: "VerticalLayout",
  },
  {
    path: "/ProfileSleep",
    component: lazy(() => import("../../view/pages/friendsSleep")),
    layout: "VerticalLayout",
  },
  {
    path: "/ProfileWeight",
    component: lazy(() => import("../../view/pages/friendsWeight")),
    layout: "VerticalLayout",
  },
];
