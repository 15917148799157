import React from "react";
import { Link } from "react-router-dom";

import { Button, Checkbox, Col, Form, Input, notification, Row } from "antd";

import Footer from "../footer";
import LeftContent from "../leftContent";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  loginUser,
  updateIsAuthenticated,
} from "../../../../redux-toolkit/features/auth/authSlice";
import { getProfileData } from "../../../../redux-toolkit/features/profile/profileSlice";

export default function Login() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isLoading } = useSelector((state) => state.auth);

  const openNotification = (error) => {
    const args = {
      message: error,

      duration: 4,
    };
    notification.open(args);
  };

  const onFinish = (values) => {
    dispatch(loginUser(values))
      .then((data) => {
        if (data?.payload?.data?.success === true) {
          console.log({ data });
          if (
            data?.payload?.data?.user?.profileCompletionStep &&
            data?.payload?.data?.user?.profileCompletionStep < 6
          ) {
            dispatch(getProfileData());
            dispatch(updateIsAuthenticated());
            localStorage.setItem(
              "profileCompletionStep",
              data?.payload?.data?.user?.profileCompletionStep + 1
            );
            history.push(
              `/user-onboarding?step=${
                data?.payload?.data?.user?.profileCompletionStep + 1
              }`
            );
          } else {
            history.push("/myprofile");
            dispatch(getProfileData());
            dispatch(updateIsAuthenticated());
          }
        }
      })
      .catch((error) => error);
  };

  return (
    <Row gutter={[32, 0]} className="hp-authentication-page">
      <LeftContent />

      <Col lg={12} span={24} className="hp-py-sm-0 hp-py-md-64">
        <Row className="hp-h-100" align="middle" justify="center">
          <Col
            xxl={11}
            xl={15}
            lg={20}
            md={20}
            sm={24}
            className="hp-px-sm-8 hp-pt-24 hp-pb-48"
          >
            <h1 className="hp-mb-sm-0">Login</h1>
            <p className="hp-mt-sm-0 hp-mt-8 hp-text-color-black-60">
              Welcome back, please login to your account.
            </p>

            <Form
              layout="vertical"
              name="basic"
              initialValues={{ remember: true }}
              className="hp-mt-sm-16 hp-mt-32"
              onFinish={onFinish}
            >
              <Form.Item
                label="Email"
                name="email"
                className="hp-mb-16"
                rules={[
                  // {
                  //   type: 'email',
                  //   message: 'The input is not valid E-mail!',
                  // },
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ]}
              >
                <Input id="email" />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                className="hp-mb-8"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
                hasFeedback
              >
                <Input.Password id="warning2" />
              </Form.Item>

              <Row align="middle" justify="space-between">
                <Form.Item className="hp-mb-0">
                  <Checkbox name="remember">Remember me</Checkbox>
                </Form.Item>

                <Link
                  className="hp-button hp-text-color-black-80 hp-text-color-dark-40"
                  to="/authentication/recover-password"
                >
                  Forgot Password?
                </Link>
              </Row>

              <Form.Item className="hp-mt-16 hp-mb-8">
                {/* <Link to='/myprofile'> */}
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  disabled={isLoading}
                >
                  Sign in
                </Button>
                {/* </Link> */}
              </Form.Item>
            </Form>

            <Col className="hp-form-info hp-text-center">
              <span className="hp-text-color-black-80 hp-text-color-dark-40 hp-caption hp-font-weight-400 hp-mr-4">
                Don’t you have an account?
              </span>

              <Link
                className="hp-text-color-primary-1 hp-text-color-dark-primary-2 hp-caption"
                to="/authentication/register"
              >
                Create an account
              </Link>
            </Col>

            <Footer />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
