import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import apiInstance from '../../apiInstance';

const initialState = {
  isLoading: false,
  usersProfile: [],
};

export const getUsersProfile = createAsyncThunk('users/profile', async (id) => {
  try {
    const response = await apiInstance({
      method: 'get',
      url: `/users/profile?userId=${id}`,
    });
    return response;
  } catch (error) { }
});

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: {
    [getUsersProfile.pending]: (state) => {
      state.isLoading = true;
    },
    [getUsersProfile.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.usersProfile = action?.payload?.data;
    },
    [getUsersProfile.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export default usersSlice.reducer;
