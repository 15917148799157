import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiInstance from '../../apiInstance';

const initialState = {
    isLoading: false,
    settings_data: []
}

export const addActivitiesToFeed = createAsyncThunk('settings/addActivitiesToFeed', async (body) => {
    try {
        const response = await apiInstance({
            method: 'post',
            url: '/settings/addActvitiesToFeedSettings',
            data: body
        });
        return response;
    }
    catch (error) { }
})

export const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {},
    extraReducers: {
        [addActivitiesToFeed.pending]: (state) => {
            state.isLoading = true;
        },
        [addActivitiesToFeed.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.settings_data = action?.payload?.data?.data;
        },
        [addActivitiesToFeed.rejected]: (state) => {
            state.isLoading = false;
        },
    },
});

export default settingsSlice.reducer;