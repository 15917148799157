import moment from "moment";
const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const localizeTime = (date, timeZone = userTimeZone) => {
  const time = new Date(date);
  const localTime = time.toLocaleString([], { timeZone: timeZone });
  const localTime2 = time.toLocaleString([], { timeZone: "America/New_York" });
  const splitted = localTime.split(",");
  const splittedDate = splitted[0];
  const spllitedTime = splitted[1];
  const newDate = new Date(splittedDate);
  const secondsRemoved = removeSeconds(spllitedTime);
  const formattedDate = moment(newDate).format("MM/DD/YYYY");
  //moment( dateTime, 'MM-DD-YYYY HH:mm:ss',true).format("YYYY-MM-DD HH:mm:ss")
  return {
    formattedDateTime: `${formattedDate} at ${secondsRemoved}`,
    formattedDate,
    formattedTime: secondsRemoved,
    localTimeRaw: localTime,
  };
  // return `${formattedDate} at ${spllitedTime}`
};
function removeSeconds(inputString) {
  // Extract the time part and AM/PM part

  // Remove the two characters after the seconds using regex
  if (inputString) {
    const stringWithoutSeconds = inputString.replace(/:\d{2}\s/, " ");
    return stringWithoutSeconds;
  }
}
export const generateDateArray = (inputDate) => {
  const format = "MM/DD/YYYY";
  const currentDate = moment(inputDate, format);

  const dateArray = [];

  // Add previous three days
  for (let i = 3; i > 0; i--) {
    const previousDate = currentDate.clone().subtract(i, "days");
    dateArray.push({
      date: previousDate.format(format),
      day: previousDate.format("Do"),
    });
  }

  // Add current day
  dateArray.push({
    date: currentDate.format(format),
    day: currentDate.format("Do"),
  });

  // Add next three days
  for (let i = 1; i <= 3; i++) {
    const nextDate = currentDate.clone().add(i, "days");
    dateArray.push({
      date: nextDate.format(format),
      day: nextDate.format("Do"),
    });
  }

  return dateArray;
};

export const formatDateForDayAndMonth = (inputDate) => {
  const format = "MM/DD/YYYY";
  const parsedDate = moment(inputDate, format);

  // Format the date as "MMMM Do"
  const formattedDate = parsedDate.format("MMMM Do");

  return formattedDate;
};

export const formatCurrentDayAndGetJustDay = (inputDate) => {
  const format = "MM/DD/YYYY";
  const parsedDate = moment(inputDate, format);

  const formattedDate = parsedDate.format("Do");

  return formattedDate;
};

export const getDateRange = (daysBefore) => {
  // Get the current date and time
  let currentDate = new Date();

  // Set the end date to the end of the current day
  let endDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
    23,
    59,
    59,
    999
  );

  // Calculate the start date by subtracting the specified number of days
  let startDate = new Date();
  startDate.setDate(currentDate.getDate() - daysBefore);
  // Set the start date to the beginning of the day
  startDate = new Date(
    startDate.getFullYear(),
    startDate.getMonth(),
    startDate.getDate(),
    0,
    0,
    0,
    0
  );

  return { startDate, endDate };
};
