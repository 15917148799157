import React, { useEffect, useState } from "react";
import moment from "moment";
import { Col, Row, Button, Tooltip, Badge } from "antd";
import Chart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RiHeartAddLine, RiExternalLinkLine } from "react-icons/ri";

import {
  actionList,
  miniStats,
} from "../../redux-toolkit/features/actvities/activitiesSlice";
import { localizeTime } from "../../utils/date";

export default function BpsampleCard({ handleBackClick }) {
  const dispatch = useDispatch();

  const [chartWidth, setChartWidth] = useState();
  const { actionListData, readingsData } = useSelector(
    (state) => state.readings
  );
  const [bpData, setBPData] = useState(false);
  const systolicData = [];
  const diastolicData = [];
  const dates = [];
  const chartData = [];

  const [options, setOptions] = useState({
    chart: {
      fontFamily: "Manrope, sans-serif",
      type: "line",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    legend: {
      show: false,
    },
    xaxis: {
      type: "category",
      categories: [],
      tooltip: {
        enabled: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    tooltip: {
      x: {
        show: true,
      },
    },
      stroke: {
        curve: "smooth",
        lineCap: "round",
      },
    grid: {
      show: false,
      padding: {
        left: 8,
        right: 15,
        top: -10,
        bottom: -10,
      },
    },
  });
  const [series, setSeries] = useState([
    {
      name: "Systolic",
      data: [],
    },
    {
      name: "Diastolic",
      data: [],
    },
  ]);

  useEffect(() => {
    readingsData?.data?.bloodPressure?.map((item) => chartData.push(item));
    const filteredData = chartData.filter(
      (data) => data.readingDate !== null && data?.reading !== null
    );

    const sortedArray = _.sortBy(filteredData, "readingDate");

    for (const obj of sortedArray) {
      systolicData.push(obj?.mm);
      diastolicData.push(obj?.hg);
      dates.push(
        `${localizeTime(obj?.readingDate).formattedDate} ${
          obj?.readingTime
            ? "at" + localizeTime(obj?.readingDate).formattedTime
            : ""
        } `
      );
    }
    setOptions({
      chart: {
        fontFamily: "Manrope, sans-serif",
        type: "line",

        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      xaxis: {
        type: "category",
        categories: dates,
        tooltip: {
          enabled: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
        },
      },
      yaxis: {
        show: false,
      },
      legend: {
        show: false,
      },
      grid: {
        show: false,
        padding: {
          left: 8,
          right: 15,
          top: -10,
          bottom: -10,
        },
      },
    });
    setSeries([
      {
        name: "Systolic",
        data: systolicData,
      },
      {
        name: "Diastolic",
        data: diastolicData,
      },
    ]);
    console.log({ actionListData });
    const bloodPressure = actionListData?.data?.bloodPressure;
    console.log({ bloodPressure });
    if (bloodPressure) {
      console.log("now i am in");
      var dateofvisitBP = moment(bloodPressure?.readingDate, "YYYY-MM-DD");
      var today = moment();
      console.log({ dateofvisitBP });
      const noOfDaysBP = today.diff(dateofvisitBP, "days").toString();
      console.log({ noOfDaysBP });
      let bpData = {
        integrationname: "Blood Pressure",
        lastUpdate:
          _.isEmpty(bloodPressure) !== true
            ? `${
                bloodPressure?.readingDate
                  ? localizeTime(bloodPressure?.readingDate).formattedDate
                  : ""
              } at  ${
                bloodPressure?.readingTime
                  ? localizeTime(bloodPressure?.readingTime).formattedTime
                  : ""
              }`
            : "No record",
        indicatorMessage:
          noOfDaysBP !== "NaN"
            ? noOfDaysBP >= 3
              ? "Your readings are out of date. Please update!"
              : "Your readings are up to date"
            : "Your readings are out of date. Please update!",
        indicator:
          noOfDaysBP !== "NaN"
            ? noOfDaysBP < 3
              ? "success"
              : noOfDaysBP >= 3
              ? noOfDaysBP >= 7
                ? "error"
                : "warning"
              : "success"
            : "error",
      };
      console.log({ bpData });
      setBPData(bpData);
    }
  }, [readingsData, actionListData]);

  return (
    <div
      className="hp-border-1 hp-overflow-hidden hp-border-color-black-40 hp-border-color-dark-80 hp-border-radius-xxl hp-bg-color-black-0 hp-bg-color-dark-100 hp-p-16 hp-card-2"
      style={{ height: "100%" }}
    >
      <Row gutter={16} align="middle">
        <Col span={24} className="hp-text-left">
          <span className="h4 hp-font-weight-800 hp-text-color-black-bg hp-text-color-dark-0 hp-d-block">
            <Link
              to="/bloodpressure"
              className="h4 hp-font-weight-800 hp-text-color-black-bg hp-text-color-dark-0 hp-d-block"
            >
              Blood Pressure
            </Link>
          </span>
          {bpData !== false ? (
            <p style={{ fontSize: "12px" }} className="hp-p1-body hp-mb-0">
              {" "}
              <Tooltip title={bpData.indicatorMessage}>
                <Badge status={bpData.indicator} />
              </Tooltip>{" "}
              Last reading {bpData.lastUpdate}
            </p>
          ) : null}
          <div
            id="chart"
            className="hp-bg-color-success-4 hp-bg-color-dark-90 hp-border-radius-xl hp-d-flex-center hp-mb-18"
          >
            <Chart
              options={options}
              series={series}
              width={chartWidth}
              height={135}
              legend="legend"
            />
          </div>

          <h3
            className="hp-mb-0 hp-font-weight-600 hp-d-flex-full-center"
            style={{ display: "flex", gap: "5px" }}
          >
            <span>
              {actionListData?.data?.bloodPressure?.mm}/
              {actionListData?.data?.bloodPressure?.hg}
            </span>
            <p className="hp-p1-body hp-mb-0">mmHg</p>
          </h3>
        </Col>
      </Row>

      <Row className="text-center hp-mt-8">
        <Col xs={12} className="mb-3">
          <Button size="small" type="text" className="hp-hover-bg-primary-4">
            <RiExternalLinkLine className="remix-icon" />
            <Link
              to="/bloodpressure"
              className="h6 hp-font-weight-500 hp-text-color-black-bg hp-text-color-dark-0 hp-d-block"
            >
              View More
            </Link>
          </Button>
        </Col>
        <Col xs={12} className="mb-3">
          <Button
            onClick={(e) => {
              e.preventDefault();
              handleBackClick();
            }}
            size="small"
            type="text"
            className="hp-hover-bg-primary-4"
          >
            <RiHeartAddLine className="remix-icon" />
            Add Entry
          </Button>
        </Col>
      </Row>
    </div>
  );
}
