import { Badge, Button, Card, Col, Row, Tooltip } from "antd";
import _ from 'lodash';
import moment from "moment";
import React from "react";
import Chart from "react-apexcharts";
import { RiAddLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import './myactionitemsCard.css';

export default function MyactionitemsCard({ ...props }) {
  const actionlist = []
  const { actionListData } = useSelector((state) => state.readings)

  actionlist.push(actionListData?.data)

  var dateofvisitBP = moment(actionlist[0]?.bloodPressure?.readingDate, 'YYYY-MM-DD');
  var dateofvisitGlucose = moment(actionlist[0]?.glucose?.readingDate, 'YYYY-MM-DD');
  var dateofvisitHeartRate = moment(actionlist[0]?.heartRate?.readingDate, 'YYYY-MM-DD');
  var dateofvisitO2 = moment(actionlist[0]?.o2Level?.readingDate, 'YYYY-MM-DD');
  var dateofvisitSleep = moment(actionlist[0]?.sleepTiming?.readingDate, 'YYYY-MM-DD');
  var dateofvisitWeight = moment(actionlist[0]?.Weight?.readingDate, 'YYYY-MM-DD');

  var today = moment();
  const noOfDaysBP = today.diff(dateofvisitBP, 'days').toString();
  const noOfDaysGlucose = today.diff(dateofvisitGlucose, 'days').toString();
  const noOfDaysHeartRate = today.diff(dateofvisitHeartRate, 'days').toString();
  const noOfDaysO2 = today.diff(dateofvisitO2, 'days').toString();
  const noOfDaysSleep = today.diff(dateofvisitSleep, 'days').toString();
  const noOfDaysWeight = today.diff(dateofvisitWeight, 'days').toString();


  const statesArr = []
  actionlist?.map((data) => {
    statesArr.push({
      integrationname: 'Blood Pressure',
      lastUpdate: _.isEmpty(data?.bloodPressure) !== true ? (`${data?.bloodPressure?.readingDate ? data?.bloodPressure?.readingDate?.slice(
        0, 10) : ''} at  ${data?.bloodPressure?.readingTime ? data?.bloodPressure?.readingTime?.slice(11, 16) : ''}`) : 'No record',
      indicatorMessage: noOfDaysBP !== 'NaN' ? (noOfDaysBP >= 3 ? "Your readings are out of date. Please update!" : "Your readings are up to date") : "Your readings are out of date. Please update!",
      indicator: noOfDaysBP !== 'NaN' ? (noOfDaysBP < 3 ? "success" : (noOfDaysBP >= 3 ? (noOfDaysBP >= 7 ? "error" : "warning") : "success")) : "error"
    },
      {
        integrationname: 'Glucose',
        lastUpdate: _.isEmpty(data?.glucose) !== true ? (`${data?.glucose?.readingDate?.slice(
          0, 10)} at ${data?.glucose?.readingTime ? data?.glucose?.readingTime?.slice(11, 16) : ''}`) : 'No record',
        indicatorMessage: noOfDaysGlucose !== 'NaN' ? (noOfDaysGlucose >= 3 ? "Your readings are out of date. Please update!" : "Your readings are up to date") : "Your readings are out of date. Please update!",
        indicator: noOfDaysGlucose !== 'NaN' ? (noOfDaysGlucose >= 3 ? (noOfDaysGlucose >= 7 ? "error" : "warning") : "success") : "error"
      },
      {
        integrationname: 'Heart Rate',
        lastUpdate: _.isEmpty(data?.heartRate) !== true ? (`${data?.heartRate?.readingDate?.slice(
          0, 10)} at ${data?.heartRate?.readingTime ? data?.heartRate?.readingTime?.slice(11, 16) : ''}`) : 'No record',
        indicatorMessage: noOfDaysHeartRate !== 'NaN' ? (noOfDaysHeartRate >= 3 ? "Your readings are out of date. Please update!" : "Your readings are up to date") : "Your readings are out of date. Please update!",
        indicator: noOfDaysHeartRate !== 'NaN' ? (noOfDaysHeartRate >= 3 ? (noOfDaysHeartRate >= 7 ? "error" : "warning") : "success") : "error"
      },
      {
        integrationname: 'Oxygen Saturation',
        lastUpdate: _.isEmpty(data?.o2Level) !== true ? (`${data?.o2Level?.readingDate?.slice(
          0, 10)} at ${data?.o2Level?.readingTime ? data?.o2Level?.readingTime?.slice(11, 16) : ''}`) : 'No record',
        indicatorMessage: noOfDaysO2 !== 'NaN' ? (noOfDaysO2 >= 3 ? "Your readings are out of date. Please update!" : "Your readings are up to date") : "Your readings are out of date. Please update!",
        indicator: noOfDaysO2 !== 'NaN' ? (noOfDaysO2 >= 3 ? (noOfDaysO2 >= 7 ? "error" : "warning") : "success") : "error"
      },
      {
        integrationname: 'Sleep',
        lastUpdate: _.isEmpty(data?.sleepTiming) !== true ? (`${data?.sleepTiming?.readingDate?.slice(
          0, 10)} at ${data?.sleepTiming?.readingTime ? data?.sleepTiming?.readingTime?.slice(11, 16) : ''}`) : 'No record',
        indicatorMessage: noOfDaysSleep !== 'NaN' ? (noOfDaysSleep >= 3 ? "Your readings are out of date. Please update!" : "Your readings are up to date") : "Your readings are out of date. Please update!",
        indicator: noOfDaysSleep !== 'NaN' ? (noOfDaysSleep >= 3 ? (noOfDaysSleep >= 7 ? "error" : "warning") : "success") : "error"
      },
      {
        integrationname: 'Weight',
        lastUpdate: _.isEmpty(data?.Weight) !== true ? (`${data?.Weight?.readingDate?.slice(
          0, 10)} at ${data?.Weight?.readingTime ? data?.Weight?.readingTime?.slice(11, 16) : ''}`) : 'No record',
        indicatorMessage: noOfDaysWeight !== 'NaN' ? (noOfDaysWeight >= 3 ? "Your readings are out of date. Please update!" : "Your readings are up to date") : "Your readings are out of date. Please update!",
        indicator: noOfDaysWeight !== 'NaN' ? (noOfDaysWeight >= 3 ? (noOfDaysWeight >= 7 ? "error" : "warning") : "success") : "error"
      }
    )
  })

  const renderStates = () => {
    return statesArr?.map((state) => {
      return (
        <div key={state.integrationname}>
          <Row justify="space-between" className="hp-mt-12">
            <Col md={11} span={12}>
              <Row style={{ display: 'flex', gap: '8px', flexWrap: 'nowrap' }} >
                <Tooltip title={state?.indicatorMessage}>
                  <Badge status={state?.indicator} />
                </Tooltip>
                <p className="hp-p1-body hp-mb-0">{state.integrationname}</p>
              </Row>
            </Col>

            <Col md={13} className="hp-text-right" span={12}>

              <Row align="end" style={{ display: 'flex', flexWrap: 'nowrap' }}>

                <p className="hp-badge-text hp-mb-0 hp-align-self-center"
                >
                  {state.lastUpdate}
                </p>
                <Button className="hp-float-right" size="small" type="text" name={state?.integrationname} onClick={(e) => {
                  window.scroll(0, 0),
                    (e?.currentTarget?.name === "Blood Pressure" ? props.handleBPClick() :
                      (e?.currentTarget?.name === 'Glucose' ? props.handleGlucoseClick() :
                        (e?.currentTarget?.name === 'Heart Rate' ? props.handleHeartRateClick() :
                          (e?.currentTarget?.name === 'Oxygen Saturation' ? props.handleO2Click() :
                            (e?.currentTarget?.name === 'Sleep' ? props.handleSleepClick() :
                              (e?.currentTarget?.name === 'Weight' ? props.handleWeightClick() : null))))))
                }}>
                  {<RiAddLine className="remix-icon" />}
                </Button>
              </Row>
            </Col>
          </Row >
        </div >
      );
    });
  };

  return (
    <Card className="hp-border-color-black-40 hp-mb-32 hp-card-3 hp-eCommerceCardOne activitiesBody">
      <Row>
        <Col span={24} className="hp-mb-8">
          <Row justify="space-between" align="middle">
            <span className="h3 hp-font-weight-600 hp-text-color-black-bg hp-text-color-dark-0 hp-d-block">Records</span>
            <p className="hp-badge-text hp-text-color-black-80 hp-text-color-dark-30 hp-mb-0">
              Last entry
            </p>

          </Row>
        </Col>

        <Col span={24} className="hp-mb-4">
          {renderStates()}
        </Col>
      </Row>
    </Card>
  );
}
