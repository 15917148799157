import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import { Divider, Avatar, Row, Col, Switch, Dropdown, Button } from "antd";
import { RiSettings3Line } from "react-icons/ri";
import { theme } from "./../../../../redux/customise/customiseActions";

import IntlMessages from "../../lang/IntlMessages";
import avatar from "../../../../assets/images/memoji/user-avatar-8.png";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

export default function MenuFooter(props) {
  const { profile_data } = useSelector((state) => state?.profile);
  const [checked, setChecked] = useState();
  const dispatch = useDispatch();
  // Theme Active
  let themeRef = useRef(null);
  themeRef = [];

  const [themeLocal, setThemeLocal] = useState();
  const [themeClickCheck, setThemeClickCheck] = useState(false);
  const location = useHistory();

  useEffect(() => {
    if (themeClickCheck === false) {
      if (location.location.search == "?theme=dark") {
        localStorage.setItem("theme", "dark");
        setThemeLocal("dark");
        setChecked(false);
      } else if (location.location.search == "?theme=light") {
        localStorage.setItem("theme", "light");
        setThemeLocal("light");
        setChecked(true);
      }
    }

    if (localStorage) {
      setThemeLocal(localStorage.getItem("theme"));
    }
    if (localStorage) {
      if (localStorage.getItem("theme") === "light") {
        setChecked(true);
      }
      if (localStorage.getItem("theme") === "dark") {
        setChecked(false);
      } else {
        setChecked(true);
      }
    } else {
      setChecked(true);
    }
  }, [props?.reloadState]);

  function themeClick(index) {
    setThemeClickCheck(true);

    if (index == 0) {
      document.querySelector("body").classList.replace("dark", "light");
      localStorage.setItem("theme", "light");
      setThemeLocal("light");

      dispatch(theme("light"));
    } else {
      document.querySelector("body").classList.replace("light", "dark");
      localStorage.setItem("theme", "dark");
      setThemeLocal("dark");

      dispatch(theme("dark"));
    }

    for (let i = 0; i < themeRef.length; i++) {
      if (index == i) {
        themeRef[i].classList.add("active");
      } else {
        themeRef[i].classList.remove("active");
      }
    }
  }

  const handleChange = (e) => {
    setChecked(e);
    e ? themeClick(0) : themeClick(1);
    localStorage.setItem("checked", e);
  };
  const items = [
    {
      key: "1",
      label: (
        <Link to="/mysettings" onClick={props.onClose}>
          My Settings
        </Link>
      ),
    },
    {
      key: "1",
      label: (
        <Link
          to="/#"
          onClick={(e) => {
            e.preventDefault();
            localStorage.clear();
            window.location.href = "/";
          }}
        >
          Logout
        </Link>
      ),
    },
  ];

  return (
    <Row
      className="hp-sidebar-footer hp-bg-color-dark-90"
      align="middle"
      justify="space-between"
    >
      <Divider className="hp-border-color-black-40 hp-border-color-dark-70 hp-mt-0" />

      <Col>
        <Row style={{ marginBottom: "15px" }}>
          <Switch
            className="hp-header-left-text"
            checkedChildren="Light Mode"
            unCheckedChildren="Dark Mode"
            checked={checked}
            onChange={(e) => {
              handleChange(e), props.reload(e);
            }}
          />
        </Row>
        <Row align="middle">
          <Avatar
            size={48}
            src={
              profile_data?.primaryPhoto !== "image.jpg"
                ? profile_data?.primaryPhoto
                : null
            }
            className="hp-bg-info-4 hp-mr-8"
          />

          <div className="hp-mt-6">
            <span
              className="hp-d-block hp-text-color-black-100 hp-text-color-dark-0 hp-p1-body"
              style={{ lineHeight: 1 }}
            >
              {profile_data?.fullName}
            </span>

            <Link
              to="/myprofile"
              className="hp-badge-text hp-text-color-dark-30 hp-font-weight-400"
              onClick={props.onClose}
            >
              <IntlMessages id="sidebar-view-profile" />
            </Link>
          </div>
        </Row>
      </Col>

      <Col>
        <Dropdown menu={{ items }} placement="top">
          <Button
            style={{
              background: "transparent",
              border: "none",
              padding: "0px",
            }}
          >
            {" "}
            <RiSettings3Line
              className="remix-icon hp-text-color-black-100 hp-text-color-dark-0"
              size={24}
            />
          </Button>
        </Dropdown>
        {/* <Link to="/mysettings" onClick={props.onClose}></Link> */}
      </Col>
    </Row>
  );
}
