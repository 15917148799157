import React, { useEffect, useRef, useState } from "react";

import { useHistory } from "react-router";

import { useDispatch } from "react-redux";
import { theme } from "../../../redux/customise/customiseActions";

import { Button, Col, Layout, Row, Switch } from "antd";
import { motion } from "framer-motion/dist/framer-motion";

import { RiMenuFill } from "react-icons/ri";
import HeaderNotifications from "./HeaderNotifications";
import HeaderText from "./HeaderText";
import HeaderUser from "./HeaderUser";

const { Header } = Layout;

export default function MenuHeader(props) {
  const { setVisible } = props;

  const [searchHeader, setSearchHeader] = useState(false);
  const [searchActive, setSearchActive] = useState(false);

  // Focus
  const inputFocusRef = useRef(null);
  const inputFocusProp = {
    ref: inputFocusRef,
  };

  // Search Active
  setTimeout(() => setSearchActive(searchHeader), 100);

  const searchClick = () => {
    setSearchHeader(true);

    setTimeout(() => {
      inputFocusRef.current.focus({
        cursor: "start",
      });
    }, 200);
  };

  // Mobile Sidebar
  const showDrawer = () => {
    setVisible(true);
    setSearchHeader(false);
  };

  const [checked, setChecked] = useState();

  // Redux
  const dispatch = useDispatch();

  // Location
  const location = useHistory();

  // Theme Active
  let themeRef = useRef(null);
  themeRef = [];

  const [themeLocal, setThemeLocal] = useState();
  const [themeClickCheck, setThemeClickCheck] = useState(false);

  useEffect(() => {
    if (themeClickCheck === false) {
      if (location.location.search == "?theme=dark") {
        localStorage.setItem("theme", "dark");
        setThemeLocal("dark");
        setChecked(false);
      } else if (location.location.search == "?theme=light") {
        localStorage.setItem("theme", "light");
        setThemeLocal("light");
        setChecked(true);
      }
    }

    if (localStorage) {
      setThemeLocal(localStorage.getItem("theme"));
    }
    if (localStorage) {
      if (localStorage.getItem("theme") === "light") {
        setChecked(true);
      }
      if (localStorage.getItem("theme") === "dark") {
        setChecked(false);
      } else {
        setChecked(true);
      }
    } else {
      setChecked(true);
    }
  }, [props?.reloadState]);

  function themeClick(index) {
    setThemeClickCheck(true);

    if (index == 0) {
      document.querySelector("body").classList.replace("dark", "light");
      localStorage.setItem("theme", "light");
      setThemeLocal("light");

      dispatch(theme("light"));
    } else {
      document.querySelector("body").classList.replace("light", "dark");
      localStorage.setItem("theme", "dark");
      setThemeLocal("dark");

      dispatch(theme("dark"));
    }

    for (let i = 0; i < themeRef.length; i++) {
      if (index == i) {
        themeRef[i].classList.add("active");
      } else {
        themeRef[i].classList.remove("active");
      }
    }
  }

  const handleChange = (e) => {
    setChecked(e);
    e ? themeClick(0) : themeClick(1);
    localStorage.setItem("checked", e);
  };

  // Children
  const headerChildren = () => {
    return (
      <>
        <Row
          className="hp-w-100 hp-position-relative"
          align="middle"
          justify="space-between"
        >
          <Col className="hp-mobile-sidebar-button hp-mr-24">
            <Button
              type="none"
              ghost
              className="hp-mobile-sidebar-button hp-border-none "
              onClick={showDrawer}
              icon={
                <RiMenuFill
                  size={24}
                  className="remix-icon hp-text-color-black-80 hp-text-color-dark-30"
                />
              }
            />
          </Col>

          <HeaderText />

          <Col>
            <Row align="middle">
              {/* {!searchHeader && (
                <Switch
                  className="hp-header-left-text"
                  checkedChildren="Light Mode"
                  unCheckedChildren="Dark Mode"
                  checked={checked}
                  onChange={(e) => {
                    handleChange(e), props.reload(e);
                  }}
                />
              )} */}
              <HeaderNotifications />
             <HeaderUser />
            </Row>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Header>
      <Row justify="center" className="hp-w-100">
        <Col span={24}>
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ type: "spring", duration: 0.5, delay: 0.1 }}
            className="hp-w-100"
          >
            {headerChildren()}
          </motion.div>
        </Col>
      </Row>
    </Header>
  );
}
