import { useEffect } from "react";
import { useSelector } from "react-redux";

import { ConfigProvider } from "antd";
import { IntlProvider } from "react-intl";
import ReactGA from "react-ga";
import AppLocale from "./languages";
ReactGA.initialize("GTM-NVVZ7PTW");
import Router from "./router/Router";

export default function App() {
  // Redux
  const customise = useSelector((state) => state.customise);

  // Lang
  const currentAppLocale = AppLocale[customise.language];

  useEffect(() => {
    document.querySelector("html").setAttribute("lang", customise.language);
  }, [customise]);
  useEffect(() => {
    console.info("hi");
  }, []);
  useEffect(() => {
    if (window.location.pathname == "/authentication/login") {
      console.info("window.location", window.location.pathname);

      ReactGA.pageview("/authentication/login"); // path
    }
    if (window.location.pathname == "/authentication/register") {
      console.info("window.location", window.location.pathname);

      ReactGA.pageview("/authentication/register"); // path
    }
  }, []);
  return (
    <ConfigProvider
      locale={currentAppLocale.antd}
      direction={customise.direction}
    >
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <Router />
      </IntlProvider>
    </ConfigProvider>
  );
}
