import React, { useState } from "react";

import { RiSendPlaneLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import SyntaxHighlighter from "react-syntax-highlighter";
import { monoBlue } from "react-syntax-highlighter/dist/esm/styles/hljs";
import {
  createPost,
  getFeeds,
  getMyPosts,
} from "../../redux-toolkit/features/feed/feedSlice";
import { replyEditor } from "../components/commentcode";

import {
  Avatar,
  Button,
  Card,
  Col,
  Comment,
  Form,
  Input,
  Row,
  Upload,
} from "antd";
import moment from "moment";
import "./feedCard.css";
import "./postcommentCard.css";

import user from "../../assets/images/memoji/memoji-1.png";

const getFile = (e) => {
  if (Array.isArray(e)) {
    return e?.file?.response?.data;
  }
  return e?.file?.response?.data?.url;
};

export default function CommentReplyEditor() {
  const { profile_data } = useSelector((state) => state.profile);
  const { firstName, lastName, primaryPhoto } = profile_data;
  const dispatch = useDispatch();
  const { TextArea } = Input;
  const [comments, setComments] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [value, setValue] = useState("");
  const [checkedCode, setCheckedCode] = useState(false);
  const [codeClass, setCodeClass] = useState(false);
  const [fileList, setFileList] = useState([]);
  const handleChange = (info) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-2);

    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file?.response?.url;
      }
      return file;
    });

    setFileList(fileList);
  };

  function toggleChecked() {
    setTimeout(() => setCodeClass(!codeClass), 100);
    setCheckedCode(!checkedCode);
  }

  const props = {
    action: "https://api-stage.ameerhamza-dev.com/api/v1/uploads/uploadFile",
    multiple: false,
    listType: "picture",
    maxCount: 1,
  };

  const handleSubmit = () => {
    if (!value) {
      return;
    }

    setSubmitting(true);

    setTimeout(() => {
      setSubmitting(false);

      setValue("");

      setComments([
        ...comments,
        {
          firstName: `${firstName}`,
          lastName: `${lastName}`,
          author: `${firstName} ${lastName}`,
          avatar: primaryPhoto,
          content: value,
          text: value,
          datetime: moment().fromNow(),
        },
      ]);
    }, 1000);
  };

  const handleCommentChange = (e) => {
    setValue(e.target.value);
  };

  // const CommentList = ({ comments }) => (
  //   <List
  //     dataSource={comments}
  //     header={`${comments.length} ${comments.length > 1 ? 'replies' : 'reply'}`}
  //     itemLayout='horizontal'
  //     renderItem={(props) => <Comment {...props} />}
  //   />
  // );

  const [form] = Form.useForm();
  const onFinish = (values) => {
    const submitVlaues = {
      text: values.text,
      assets: [],
    };
    if (values.assets) {
      submitVlaues.assets.push({
        type: "photo",
        url: values.assets,
      });
    }
    form.resetFields();

    dispatch(createPost(submitVlaues))
      .then(() => {
        // return;
        dispatch(getMyPosts());
        dispatch(getFeeds());
      })
      .catch((error) => error);
  };

  return (
    <Card className="hp-border-color-black-40" style={{ height: "100%" }}>
      <Row>
        <Col className="hp-mb-16" lg={16} span={20}>
          <span className="h3 hp-font-weight-600 hp-text-color-black-bg hp-text-color-dark-0 hp-d-block">
            Share a Post
          </span>
          <p className="hp-p1-body hp-mt-4 hp-mb-0">
            This will be visible to your network.
          </p>
        </Col>

        <Col span={24}>
          {/* {comments.length > 0 && <CommentList comments={comments} />} */}

          <Comment
            avatar={<Avatar src={primaryPhoto} alt="Dolores O'Riordan" />}
            content={
              <Form onFinish={onFinish} form={form}>
                <Form.Item name="text">
                  <TextArea
                    rows={3}
                    onChange={handleCommentChange}
                    // value={value}
                    key={2}
                  />
                </Form.Item>

                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    float: "right",
                    marginTop: "-70px",
                    marginRight: "10px",
                  }}
                >
                  <Form.Item
                    name="assets"
                    valuePropName="file"
                    getValueFromEvent={getFile}
                    style={{
                      display: "flex",
                    }}
                  >
                    <Upload
                      {...props}
                      onChange={handleChange}
                      fileList={fileList}
                    >
                      <Button
                        type="text"
                        icon={<i className="ri-image-add-line" />}
                      />
                    </Upload>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      htmlType="primary"
                      icon={<RiSendPlaneLine className="remix-icon" />}
                      shape="circle"
                      loading={submitting}
                      onClick={handleSubmit}
                      type="primary"
                    />
                  </Form.Item>
                </div>
              </Form>
            }
          />
        </Col>
      </Row>

      {checkedCode && (
        <SyntaxHighlighter
          language="javascript"
          className={`show-code hp-mt-24 ${codeClass && "show-code-active"}`}
          style={monoBlue}
        >
          {replyEditor}
        </SyntaxHighlighter>
      )}
    </Card>
  );
}
