import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiInstance from "../../apiInstance";

const initialState = {
  isLoading: false,
  profile_data: [],
};

export const getProfileData = createAsyncThunk("profile/getMe", async () => {
  try {
    const response = await apiInstance({
      method: "get",
      url: "/auth/getMe",
    });
    return response;
  } catch (error) {}
});

export const updateBasicProfile = createAsyncThunk(
  "profile/updateBasicProfile",
  async (body) => {
    try {
      const response = await apiInstance({
        method: "post",
        url: "/auth/updateBasicProfile",
        data: body,
      });
      return response;
    } catch (error) {}
  }
);

export const updateProfilePicture = createAsyncThunk(
  "profile/updateProfilePicture",
  async (body) => {
    try {
      const response = await apiInstance({
        method: "post",
        url: "/auth/updateProfilePicture",
        data: body,
      });
      return response;
    } catch (error) {}
  }
);

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers: {
    [getProfileData.pending]: (state) => {
      state.isLoading = true;
    },
    [getProfileData.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.profile_data = action?.payload?.data?.data;
    },
    [getProfileData.rejected]: (state) => {
      state.isLoading = false;
    },
    [updateBasicProfile.pending]: (state) => {
      state.isLoading = true;
    },
    [updateBasicProfile.fulfilled]: (state, action) => {
      state.isLoading = false;
      // state.profile_data = action?.payload?.data?.data;
    },
    [updateBasicProfile.rejected]: (state) => {
      state.isLoading = false;
    },
    [updateProfilePicture.pending]: (state) => {
      state.isLoading = true;
    },
    [updateProfilePicture.fulfilled]: (state, action) => {
      state.isLoading = false;
      // state.profile_data = action?.payload?.data?.data;
    },
    [updateProfilePicture.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export default profileSlice.reducer;
