import React, { useState } from "react";

import { RiSendPlaneLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import SyntaxHighlighter from "react-syntax-highlighter";
import { monoBlue } from "react-syntax-highlighter/dist/esm/styles/hljs";

import { replyEditor } from "../components/commentcode";

import { Avatar, Button, Col, Comment, Form, Input, List, Row } from "antd";
import moment from "moment";

import { useHistory } from "react-router-dom";
import {
  addNewComment,
  getFeeds,
} from "../../redux-toolkit/features/feed/feedSlice";
import { getUsersProfile } from "../../redux-toolkit/features/users/usersSlice";
import "./feedCard.css";
import { localizeTime } from "../../utils/date";

export default function CommentReply({ ...props }) {
  const [form] = Form.useForm();
  const { profile_data } = useSelector((state) => state.profile);
  const { firstName, lastName, primaryPhoto } = profile_data;
  const { usersProfile } = useSelector((state) => state.users);

  const { feeds } = useSelector((state) => state.feed);
  const {
    data: {
      posts = [],
      lastPage = null,
      nextPage = null,
      page = 1,
      pageSize = 10,
      prevPage = null,
      totalPages = 1,
      totalPosts = 10,
    },
  } = feeds;
  const dispatch = useDispatch();
  const { TextArea } = Input;
  const [comments1, setComments1] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [value, setValue] = useState("");
  const [checkedCode, setCheckedCode] = useState(false);
  const [codeClass, setCodeClass] = useState(false);
  const history = useHistory();

  const listData = [];
  for (let i = 0; i < feeds?.data?.posts?.length; i++) {
    for (let j = 0; j < feeds?.data?.posts[i]?.comments?.length; j++) {
      listData.push({
        commentId: `${feeds?.data?.posts[i]?.id}`,
        comments: `${feeds?.data?.posts[i]?.comments[j]?.text} \n`,
        createdAt: `${
          localizeTime(feeds?.data?.posts[i]?.comments[j]?.createdAt)
            .formattedDateTime
        }`,
        commentsFirstName: `${feeds?.data?.posts[i]?.comments[j]?.user?.firstName}`,
        commentsLastName: `${feeds?.data?.posts[i]?.comments[j]?.user?.lastName}`,
        commentsLength: `${feeds?.data?.posts[i]?.comments?.length}`,
        primPhoto: `${feeds?.data?.posts[i]?.comments[j]?.user?.primaryPhoto}`,
      });
    }
  }

  const commentsData = [];
  console.log({ sss: usersProfile?.data?.posts });
  for (let i = 0; i < usersProfile?.data?.posts?.length; i++) {
    for (let j = 0; j < usersProfile?.data?.posts[i]?.comments?.length; j++) {
      commentsData.push({
        title: `${usersProfile?.data?.profile?.firstName} ${usersProfile?.data?.profile?.lastName}`,
        commentId: `${usersProfile?.data?.posts[i]?.id}`,
        comments: `${usersProfile?.data?.posts[i]?.comments[j]?.text}`,
        commentsFirstName: `${usersProfile?.data?.posts[i]?.comments[j]?.user?.firstName}`,
        createdAt: `${
          localizeTime(usersProfile?.data?.posts[i]?.comments[j]?.createdAt)
            .formattedDateTime
        }`,
        commentsLastName: `${usersProfile?.data?.posts[i]?.comments[j]?.user?.lastName}`,
        commentsLength: `${usersProfile?.data?.posts[i]?.comments?.length}`,
        primPhoto: `${usersProfile?.data?.posts[i]?.comments[j]?.user?.primaryPhoto}`,
      });
    }
  }

  const filteredComments = listData.filter(
    (postComment) => postComment?.commentId === props?.id
  );

  const filteredPostComments = commentsData.filter(
    (postComments) => postComments?.commentId === props?.id
  );

  const handleSubmit = () => {
    if (!value) {
      return;
    }

    setSubmitting(true);

    setTimeout(() => {
      setSubmitting(false);

      setValue("");

      setComments1([
        ...comments1,
        {
          firstName: `${firstName}`,
          lastName: `${lastName}`,
          author: `${firstName} ${lastName}`,
          avatar: primaryPhoto,
          content: value,
          text: value,
          datetime: moment().fromNow(),
        },
      ]);
    }, 1000);
  };

  const handleChange = (e) => {
    setValue(e.target.value);
    props.text = value;
  };

  const onFinish = (values) => {
    // console.log({ values });
    // setValue("");

    const setComment = {
      postId: props.id,
      text: values.text,
    };
    form.resetFields();
    // values.text = "";
    dispatch(addNewComment(setComment)).then(() => {
      dispatch(getUsersProfile(props.name)).then(() => {
        dispatch(getFeeds(page));
      });
    });
  };

  return (
    <>
      <Row
        span={24}
        style={props.showComment === false ? { display: "none" } : null}
      >
        <Col span={24}>
          <List
            dataSource={
              filteredComments.length === 0
                ? filteredPostComments
                : filteredComments
            }
            itemLayout="horizontal"
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  avatar={<Avatar src={item?.primPhoto} />}
                  title={<>{item?.commentsFirstName}</>}
                  description={
                    <div style={{ textAlign: "left", whiteSpace: "pre-line" }}>
                      {item?.comments}
                      {item?.createdAt?.toString()}
                    </div>
                  }
                />
              </List.Item>
            )}
          />

          <Comment
            avatar={<Avatar src={primaryPhoto} alt="Dolores O'Riordan" />}
            content={
              <Form form={form} onFinish={onFinish}>
                <Form.Item name="text" noStyle>
                  <TextArea
                    rows={3}
                    onChange={handleChange}
                    value={value}
                    key={2}
                  />
                </Form.Item>

                <Form.Item noStyle>
                  <Button
                    htmlType="primary"
                    icon={
                      <RiSendPlaneLine
                        className="remix-icon"
                        // style={{ height: '10px' }}
                      />
                    }
                    shape="circle"
                    loading={submitting}
                    onClick={handleSubmit}
                    type="primary"
                    style={{
                      marginLeft: "auto",
                      float: "right",
                      marginTop: "-50px",
                      marginRight: "10px",
                    }}
                  />
                </Form.Item>
              </Form>
            }
          />
        </Col>
      </Row>

      {checkedCode && (
        <SyntaxHighlighter
          language="javascript"
          className={`show-code hp-mt-24 ${codeClass && "show-code-active"}`}
          style={monoBlue}
        >
          {replyEditor}
        </SyntaxHighlighter>
      )}
    </>
  );
}
