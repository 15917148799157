import React from "react";

import BpsampleCard from "../components/bpsampleCard";
import GlucoseSampleCard from "./glucoseSampleCard";
import HeartRateSampleCard from "./heartRateSampleCard";
import OxygenSampleCard from "./oxygenSampleCard";
import SleepSampleCard from "./sleepSampleCard";
import WeightSampleCard from "./weightSampleCard";

const Components = {
    bpSampleCard: BpsampleCard,
    glucoseSampleCard: GlucoseSampleCard,
    weightSampleCard: WeightSampleCard,
    heartRateSampleCard: HeartRateSampleCard,
    oxygenSampleCard: OxygenSampleCard,
    sleepSampleCard: SleepSampleCard
};

export default block => {
    if (typeof Components[block.component] !== "undefined") {
        return React.createElement(Components[block.component], {
            key: block._uid,
            block: block
        });
    }
    return React.createElement(
        () => <div>{block.component}</div>,
        { key: block._uid }
    );
};
