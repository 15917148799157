import axios from 'axios';
import { REACT_BASE_URL } from './constants';
import store from './store';

const request = axios.create({
  baseURL: REACT_BASE_URL,
});

/**
 *  refresh user token
 * @param {payload} param0
 */

function refreshUserToken({ refreshToken }) {
  return axios({
    method: 'POST',
    url: `${REACT_BASE_URL}/admin/refresh`,
    headers: { Authorization: `Bearer ${refreshToken}` },
  });
}

request.interceptors.request.use((config) => {
  const states = store.getState();
  const login_token = states?.auth?.token;
  // const reg_token = states?.auth?.register_token;
  // console.log(reg_token);

  // config.headers.Authorization = `Bearer ${final_token}`;
  const {
    auth: { token },
  } = store.getState();
  const { noToken } = config.headers;
  delete config.headers.noToken;

  if (!token || noToken) return config;

  const newConfig = {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${login_token}`,
    },
  };
  console.log(login_token);

  return newConfig;
});

//Add a response interceptor
request.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const storeState = store.getState();
    const { auth, user } = storeState;
    const token = auth?.token;
    const userData = user?.data;

    if (error?.response?.status === 401) {
      try {
        return error?.response?.data;
      } catch (error) {
        console.log(error);
      }
    } else {
      return error?.response?.data?.error;
    }

    return Promise.reject(error);
  }
);

export default request;
