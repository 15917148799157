import React, { useEffect, useState } from "react";

import { Col, Row, Button, Tooltip, Badge } from "antd";
import Chart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RiHeartAddLine, RiExternalLinkLine } from "react-icons/ri";
import moment from "moment";
import _ from "lodash";
import { localizeTime } from "../../utils/date";
export default function SleepSampleCard({ handleSleepClick }) {
  const [chartWidth, setChartWidth] = useState();
  const { actionListData, readingsData } = useSelector(
    (state) => state.readings
  );
  const [sleepData, setSleepData] = useState(false);
  const m1 = moment(
    actionListData?.data?.sleepTiming?.sleepTime,
    "DD-MM-YYYY HH:mm"
  );
  const m2 = moment(
    actionListData?.data?.sleepTiming?.wokeUpTime,
    "DD-MM-YYYY HH:mm"
  );
  const m3 = m2.diff(m1, "minutes");
  var numhours = Math.floor((m3 % 1440) / 60);
  var numminutes = Math.floor((m3 % 1440) % 60);
  var readingMin = readingsData?.data?.sleepTiming[0]?.hoursSlept || false;

  const [options, setOptions] = useState({
    chart: {
      fontFamily: "Manrope, sans-serif",
      type: "line",

      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    xaxis: {
      type: "category",
      categories: [],
      tooltip: {
        enabled: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
    stroke: {
      curve: "smooth",
      lineCap: "round",
    },
    yaxis: {
      show: false,
    },
    legend: {
      show: false,
    },
    grid: {
      show: false,
      padding: {
        left: 8,
        right: 15,
        top: -10,
        bottom: -10,
      },
    },
  });
  const [series, setSeries] = useState([
    {
      name: "Sleep",
      data: [],
    },
  ]);

  const readings = [];
  const dates = [];
  const chartData = [];

  useEffect(() => {
    readingsData?.data?.sleepTiming?.map((item) => chartData.push(item));
    const filteredBpData = chartData.filter(
      (data) => data?.readingDate !== null && data?.hoursSlept !== null
    );

    const sortedArray = _.sortBy(filteredBpData, "readingDate");

    const timings = [];
    sortedArray?.map((data) => {
      timings.push({
        hoursSlept: data?.hoursSlept,
      });
    });

    const sleep_data = [];

    for (var j = 0; j < timings.length; j++) {
      sleep_data.push({
        readingDate: sortedArray[j]?.readingDate,
        readingTime: sortedArray[j]?.readingTime,
        hoursSlept: timings[j].hoursSlept,
      });
    }

    for (const obj of sleep_data) {
      readings.push(parseInt(obj?.hoursSlept));
      dates.push(
        `${localizeTime(obj?.readingDate).formattedDate} ${
          obj?.readingTime
            ? "at" + localizeTime(obj?.readingDate).formattedTime
            : ""
        } `
      );
    }
    setOptions({
      chart: {
        fontFamily: "Manrope, sans-serif",
        type: "line",

        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      xaxis: {
        type: "category",
        categories: dates,
        tooltip: {
          enabled: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
        },
      },
      yaxis: {
        show: false,
      },
      legend: {
        show: false,
      },
      grid: {
        show: false,
        padding: {
          left: 8,
          right: 15,
          top: -10,
          bottom: -10,
        },
      },
    });
    setSeries([
      {
        name: "Sleep Timing",
        data: readings,
      },
    ]);
    const data = actionListData?.data;
    var dateofvisitSleep = moment(data?.sleepTiming?.readingDate, "YYYY-MM-DD");
    var today = moment();
    const noOfDaysSleep = today.diff(dateofvisitSleep, "days").toString();

    if (data) {
      const sleepData = {
        integrationname: "Sleep",
        lastUpdate:
          _.isEmpty(data?.sleepTiming) !== true
            ? `${
                localizeTime(data?.sleepTiming?.readingDate).formattedDate
              } at ${
                data?.sleepTiming?.readingTime
                  ? localizeTime(data?.sleepTiming?.readingTime).formattedTime
                  : ""
              }`
            : "No record",
        indicatorMessage:
          noOfDaysSleep !== "NaN"
            ? noOfDaysSleep >= 3
              ? "Your readings are out of date. Please update!"
              : "Your readings are up to date"
            : "Your readings are out of date. Please update!",
        indicator:
          noOfDaysSleep !== "NaN"
            ? noOfDaysSleep >= 3
              ? noOfDaysSleep >= 7
                ? "error"
                : "warning"
              : "success"
            : "error",
      };
      setSleepData(sleepData);
    }
  }, [readingsData, actionListData]);

  return (
    <div className="hp-border-1 hp-overflow-hidden hp-border-color-black-40 hp-border-color-dark-80 hp-border-radius-xxl hp-bg-color-black-0 hp-bg-color-dark-100 hp-p-16 hp-card-2">
      <Row gutter={16} align="middle">
        <Col span={24} className="hp-text-left">
          <span className="h4 hp-font-weight-800 hp-text-color-black-bg hp-text-color-dark-0 hp-d-block">
            <Link
              to="/sleep"
              className="h4 hp-font-weight-800 hp-text-color-black-bg hp-text-color-dark-0 hp-d-block"
            >
              Sleep
            </Link>
          </span>
          {sleepData !== false ? (
            <p style={{ fontSize: "12px" }} className="hp-p1-body hp-mb-0">
              {" "}
              <Tooltip title={sleepData.indicatorMessage}>
                <Badge status={sleepData.indicator} />
              </Tooltip>{" "}
              Last reading {sleepData.lastUpdate}
            </p>
          ) : null}

          <div
            id="chart"
            className="hp-bg-color-success-4 hp-bg-color-dark-90 hp-border-radius-xl hp-d-flex-center hp-mb-18"
          >
            <Chart
              options={options}
              series={series}
              width={chartWidth}
              height={135}
            />
          </div>

          <h3
            className="hp-mb-0 hp-font-weight-600 hp-d-flex-full-center"
            style={{ display: "flex", gap: "5px", whiteSpace: "nowrap" }}
          >
            <span>{readingMin ? `${readingMin}hrs` : ""}</span>
          </h3>
        </Col>
      </Row>

      <Row className="text-center hp-mt-8">
        <Col xs={12} className="mb-3">
          <Button size="small" type="text" className="hp-hover-bg-primary-4">
            <RiExternalLinkLine className="remix-icon" />
            <Link
              to="/sleep"
              className="h6 hp-font-weight-500 hp-text-color-black-bg hp-text-color-dark-0 hp-d-block"
            >
              View More
            </Link>
          </Button>
        </Col>
        <Col xs={12} className="mb-3">
          <Button
            onClick={(e) => {
              e.preventDefault();
              handleSleepClick();
            }}
            size="small"
            type="text"
            className="hp-hover-bg-primary-4"
          >
            <RiHeartAddLine className="remix-icon" />
            Add Entry
          </Button>
        </Col>
      </Row>
    </div>
  );
}
