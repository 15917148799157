import { combineReducers } from 'redux';

import calendarReducer from '../redux/calendar/calendarReducer';
import contactReducer from '../redux/contact/contactReducer';
import customiseReducer from '../redux/customise/customiseReducer';
import ecommerceReducer from '../redux/ecommerce/ecommerceReducer';
import readingSlice from './features/actvities/activitiesSlice';
import authSlice from './features/auth/authSlice';
import feedSlice from './features/feed/feedSlice';
import networkSlice from './features/network/networkSlice';
import notificationsSlice from './features/notifications/notificationsSlice';
import profileSlice from './features/profile/profileSlice';
import searchSlice from './features/search/searchSlice';
import settingsSlice from './features/settings/settingsSlice';
import usersSlice from './features/users/usersSlice';

const rootReducer = combineReducers({
  auth: authSlice,
  profile: profileSlice,
  feed: feedSlice,
  readings: readingSlice,
  search: searchSlice,
  users: usersSlice,
  settings: settingsSlice,
  network: networkSlice,
  notifications: notificationsSlice,
  calendar: calendarReducer,
  contact: contactReducer,
  ecommerce: ecommerceReducer,
  customise: customiseReducer,
});

export default rootReducer;
