import React from 'react';

import { Col, Row } from 'antd';
import { useEffect } from 'react';
import ActivitiesoverviewCard from '../../components/activitiesoverviewCard';
import FeedCard from '../../components/feedCard';
import IntegoverviewCard from '../../components/integoverviewCard';
import MyactionitemsCard from '../../components/myactionitemsCard';
import PostcommentCard from '../../components/postcommentCard';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getFeeds } from '../../../redux-toolkit/features/feed/feedSlice';
import { getProfileData } from '../../../redux-toolkit/features/profile/profileSlice';
import { searchUsers } from '../../../redux-toolkit/features/search/searchSlice';

import './dashboard.css';

export default function Dashboard() {
  const dispatch = useDispatch();
  const history = useHistory()

  const { profile_data } = useSelector((state) => state.profile);
  const { firstName, lastName, phone } = profile_data;


  useEffect(() => {
    dispatch(getFeeds());
    dispatch(searchUsers());
    dispatch(getProfileData());
  }, []);


  const handleClick = () => {
    history.push("/activities", state)
  }

  const handleBPClick = () => {
    const state = 'BP'
    history.push("/activities", state)
  }

  const handleGlucoseClick = () => {
    const state = 'glucose'
    history.push("/activities", state)
  }

  const handleHeartRateClick = () => {
    const state = 'heartRate'
    history.push("/activities", state)
  }

  const handleO2Click = () => {
    const state = 'o2'
    history.push("/activities", state)
  }

  const handleSleepClick = () => {
    const state = 'sleep'
    history.push("/activities", state)
  }

  const handleWeightClick = () => {
    const state = 'weight'
    history.push("/activities", state)
  }

  return (
    <Row gutter={[32, 32]}>
      <Col sm={12} span={24} align='left' justify='space-between'>
        <span className='h3 hp-font-weight-800 hp-text-color-black-bg hp-text-color-dark-0 hp-d-block'>
          Hello, {firstName} {lastName} 👋
        </span>
      </Col>

      <Col span={24}>
        <Row gutter={[32, 32]}>
          <Col xl={9} md={8} span={24}>
            <PostcommentCard />
          </Col>

          <Col xl={15} md={16} span={24}>
            <Row gutter={[16, 16]}>
              <Col md={24} span={24}>
                <ActivitiesoverviewCard />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>

      <Col xl={8} md={9} sm={24} xs={24} className="recordsHide">
        <MyactionitemsCard
          handleBPClick={handleBPClick}
          handleGlucoseClick={handleGlucoseClick}
          handleHeartRateClick={handleHeartRateClick}
          handleO2Click={handleO2Click}
          handleSleepClick={handleSleepClick}
          handleWeightClick={handleWeightClick}
        />
        <IntegoverviewCard />
      </Col>

      <Col xl={16} md={15} sm={24} xs={24}>
        <FeedCard />
      </Col>

      <Col xl={8} md={9} sm={24} xs={24} className="recordsShow">
        <MyactionitemsCard
          handleBPClick={handleBPClick}
          handleGlucoseClick={handleGlucoseClick}
          handleHeartRateClick={handleHeartRateClick}
          handleO2Click={handleO2Click}
          handleSleepClick={handleSleepClick}
          handleWeightClick={handleWeightClick}
        />
        <IntegoverviewCard />
      </Col>
    </Row>
  );
}
