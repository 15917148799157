import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiInstance from "../../apiInstance";

const initialState = {
  isLoading: false,
  feed_data: [],
  feeds: [],
  likes: [],
  comments: [],
  commentsData: [],
  deletedData: [],
  getMyPostsData: [],
};

export const createPost = createAsyncThunk("feed/createPost", async (body) => {
  try {
    const response = await apiInstance({
      method: "post",
      url: "/feed/createPost",
      data: body,
    });
    return response;
  } catch (error) {}
});

export const deleteMyPost = createAsyncThunk(
  "feed/deleteMyPost",
  async (body) => {
    try {
      const response = await apiInstance({
        method: "post",
        url: "/feed/deleteMyPost",
        data: body,
      });
      return response;
    } catch (error) {}
  }
);

export const getFeeds = createAsyncThunk("feed/getMyFeed", async (page = 1) => {
  try {
    const response = await apiInstance({
      method: "get",
      url: `/feed/getMyFeed?page=${page}`,
    });
    return response;
  } catch (error) {}
});

export const likeOrUnLike = createAsyncThunk(
  "feed/likeOrUnLike",
  async (body) => {
    try {
      const response = await apiInstance({
        method: "post",
        url: "/feed/likeOrUnLike",
        data: body,
      });
      return response;
    } catch (error) {}
  }
);

export const addNewComment = createAsyncThunk(
  "feed/addNewComment",
  async (body) => {
    try {
      const response = await apiInstance({
        method: "post",
        url: "/feed/addNewComment",
        data: body,
      });
      return response;
    } catch (error) {}
  }
);

export const getMyPosts = createAsyncThunk(
  "feed/getMyPosts",
  async (page = 1) => {
    try {
      const response = await apiInstance({
        method: "get",
        url: `/feed/getMyPosts?page=${page}`,
      });
      return response;
    } catch (error) {}
  }
);

export const feedSlice = createSlice({
  name: "feed",
  initialState,
  reducers: {
    allComments(state, action) {
      state.commentsData = action.payload;
    },
  },
  extraReducers: {
    [createPost.pending]: (state) => {
      state.isLoading = true;
    },
    [createPost.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.feed_data = action?.payload?.data;
    },
    [createPost.rejected]: (state) => {
      state.isLoading = false;
    },
    [getFeeds.pending]: (state) => {
      state.isLoading = true;
    },
    [getFeeds.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.feeds = action?.payload?.data;
    },
    [getFeeds.rejected]: (state) => {
      state.isLoading = false;
    },
    [likeOrUnLike.pending]: (state) => {
      state.isLoading = true;
    },
    [likeOrUnLike.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.likes = action?.payload?.data;
    },
    [likeOrUnLike.rejected]: (state) => {
      state.isLoading = false;
    },
    [addNewComment.pending]: (state) => {
      state.isLoading = true;
    },
    [addNewComment.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.comments = action?.payload?.data;
    },
    [addNewComment.rejected]: (state) => {
      state.isLoading = false;
    },
    [deleteMyPost.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteMyPost.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.deletedData = action?.payload?.data;
    },
    [deleteMyPost.rejected]: (state) => {
      state.isLoading = false;
    },
    [getMyPosts.pending]: (state) => {
      state.isLoading = true;
    },
    [getMyPosts.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.getMyPostsData = action?.payload?.data;
    },
    [getMyPosts.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const { allComments } = feedSlice.actions;

export default feedSlice.reducer;
