import React from "react";

import { Button, Card, Col, Row } from "antd";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import Slider from "react-slick";

import BpsampleCard from "../components/bpsampleCard";
import Components from "./components.jsx";
import GlucoseSampleCard from "./glucoseSampleCard";
import HeartRateSampleCard from "./heartRateSampleCard";
import OxygenSampleCard from "./oxygenSampleCard";
import SleepSampleCard from "./sleepSampleCard";
import WeightSampleCard from "./weightSampleCard";


import './activitiesoverviewCard.css';

export default function ActivitiesoverviewCard() {
  // Slider
  function SampleNextArrow(props) {
    const { onClick } = props;

    return (
      <Button
        onClick={onClick}
        className="hp-dashboard-ecommerce-product-slider-button next-button"
        icon={<RiArrowRightSLine className="remix-icon" size={24} />}
      ></Button>
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;

    return (
      <Button
        onClick={onClick}
        className="hp-dashboard-ecommerce-product-slider-button prev-button"
        icon={<RiArrowLeftSLine className="remix-icon" size={24} />}
      ></Button>
    );
  }

  const components = [
    {
      title: "BpsampleCard",
      component: <BpsampleCard />
    },
    {
      title: "GlucoseSampleCard",
      component: <GlucoseSampleCard />

    },
    {
      title: "HeartRateSampleCard",
      component: <HeartRateSampleCard />
    },
    {
      title: "OxygenSampleCard",
      component: <OxygenSampleCard />
    },
    {
      title: "SleepSampleCard",
      component: <SleepSampleCard />
    },
    {
      title: "WeightSampleCard",
      component: <WeightSampleCard />
    },
  ];

  const products = []

  const productCardMap = components?.map(block => Components(block));

  return (
    <Card>
      <Row gutter={[32, 0]} className="hp-dashboard-ecommerce-product-slider hp-mt-12" style={{ marginRight: '-16px' }}>
        <Col span={16} className="hp-mb-24">
          <span className="h3 hp-font-weight-600 hp-text-color-black-bg hp-text-color-dark-0 hp-d-block">Vitals Overview</span>
        </Col>

        <Slider
          dots={false}
          infinite={false}
          slidesToShow={3}
          slidesToScroll={1}
          nextArrow={<SampleNextArrow />}
          prevArrow={<SamplePrevArrow />}
          responsive={[
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }
          ]}
        >
          {productCardMap}
        </Slider>
      </Row>
    </Card>
  );
}
