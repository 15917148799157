import React, { useState } from "react";

import SyntaxHighlighter from "react-syntax-highlighter";
import { monoBlue } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { vertical } from "./feedcode.js";

import {
  Avatar,
  Button,
  Card,
  Col,
  List,
  notification,
  Radio,
  Row,
  Space,
  Pagination,
} from "antd";

import {
  RiCheckboxCircleFill,
  RiCloseFill,
  RiChat1Line,
  RiHeartLine,
} from "react-icons/ri";
import { localizeTime } from "./../../utils/date";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewComment,
  deleteMyPost,
  getFeeds,
  likeOrUnLike,
} from "../../redux-toolkit/features/feed/feedSlice.jsx";

import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import user from "../../assets/images/memoji/memoji-1.png";
import {
  friendsActionList,
  friendsMiniStats,
} from "../../redux-toolkit/features/actvities/activitiesSlice.jsx";
import { getUsersProfile } from "../../redux-toolkit/features/users/usersSlice.jsx";
import CommentReply from "./commentCardLayout.jsx";
import "./feedCard.css";

export default function FeedCard() {
  const [show, setShow] = useState([]);
  const [checkedCode, setCheckedCode] = useState(false);
  const [codeClass, setCodeClass] = useState(false);
  const [value, setValue] = useState("both");

  const dispatch = useDispatch();
  const { feeds } = useSelector((state) => state.feed);
  console.log({ feeds });
  const {
    data: {
      posts = [],
      lastPage = null,
      nextPage = null,
      page = 1,
      pageSize = 10,
      prevPage = null,
      totalPages = 1,
      totalPosts = 10,
    },
  } = feeds;
  console.log({
    // data,
    posts,
    lastPage,
    nextPage,
    page,
    pageSize,
    prevPage,
    totalPages,
  });
  const { profile_data } = useSelector((state) => state.profile);
  const history = useHistory();

  const listData = [];
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  for (let i = 0; i < feeds?.data?.posts?.length; i++) {
    const type = feeds?.data?.posts[i]?.type;

    let localized = {};
    let dataSource = "Manual";

    if (type === "activities") {
      let activity = feeds?.data?.posts[i]?.activity;
      if (!activity) {
        localized = localizeTime(feeds?.data?.posts[i]?.createdAt);
      } else {
        const { readingDate, readingTime, source } = activity;
        if (source) {
          dataSource = source;
        }
        if (readingDate && readingTime) {
          const localizedDate = localizeTime(readingDate);
          const localizedTime = localizeTime(readingTime);
          localized.formattedDate = localizedDate.formattedDate;
          localized.formattedTime = localizedTime.formattedTime;
        } else {
          localized = localizeTime(feeds?.data?.posts[i]?.createdAt);
        }
      }
    } else {
      localized = localizeTime(feeds?.data?.posts[i]?.createdAt);
    }
    listData.push({
      href: "/",
      title: `${feeds?.data?.posts[i]?.user?.firstName} ${feeds?.data?.posts[i]?.user?.lastName}`,
      avatar: `${user}`,
      description: `Data source: ${dataSource}`,
      content: `${feeds?.data?.posts[i]?.text}`,
      id: `${feeds?.data?.posts[i]?.user?.id}`,
      commentId: `${feeds?.data?.posts[i]?.id}`,
      likes: `${feeds?.data?.posts[i]?.likes?.length}`,
      comments: `${feeds?.data?.posts[i]?.comments?.length}`,
      key: `${i}`,
      assets: feeds?.data?.posts[i]?.assets[0]?.url,
      primPhoto: `${feeds?.data?.posts[i]?.user?.primaryPhoto}`,
      createDate: localized.formattedDate,
      createdDateTime: localized.formattedTime,
      createTime: `${feeds?.data?.posts[i]?.createdAt.slice(11, 16)}`,
      timeStamp: `${feeds?.data?.posts[i]?.createdAt.slice(
        0,
        10
      )} at ${feeds?.data?.posts[i]?.createdAt.slice(11, 16)}`,
      type: `${feeds.data?.posts[i]?.type}`,
      // extraInfo: `${feeds?.data?.posts[i]?.text.split(' \n', 2)[0]}`,
      activityType: `${feeds?.data?.posts[i]?.activity?.type}`,
      activity: `${feeds?.data?.posts[i]?.activity}`,
      mm: `${feeds?.data?.posts[i]?.activity?.mm}`,
      hg: `${feeds?.data?.posts[i]?.activity?.hg}`,
      reading: `${feeds?.data?.posts[i]?.activity?.reading}`,
      oxyReading: `${feeds?.data?.posts[i]?.activity?.oxyReading}`,
      unit: `${feeds?.data?.posts[i]?.activity?.unit}`,
    });
    console.log({ listData });
  }

  const IconText = ({ icon, text }) => (
    <Space>
      {icon}
      {text}
    </Space>
  );

  const handleLikeClick = (e) => {
    e.preventDefault();
    let postId = {
      postId: e?.currentTarget?.name,
    };
    dispatch(likeOrUnLike(postId)).then(() => {
      dispatch(getFeeds(page));
    });
  };

  const ids = [];

  const feds = feeds?.data?.posts?.map((item, id) =>
    ids.push({
      key: id,
      id: item?.id,
      showComments: false,
    })
  );

  const handleCommentClick = (e) => {
    e.preventDefault();
    const id = e?.currentTarget?.name;

    const filteredId = ids
      .filter((filteredArray) => filteredArray.id === id)
      .map((obj) => {
        if (obj.showComments === false) {
          return {
            key: obj.key,
            id: obj.id,
            showComments: (obj.showComments = true),
          };
        }
        if (obj.showComments === true) {
          return {
            key: obj.key,
            id: obj.id,
            showComments: (obj.showComments = false),
          };
        }
      });
    setShow(ids);

    return ids;
  };

  const handlePicClick = (id) => {
    dispatch(getUsersProfile(id)).then((data) => {
      history.push("/profile");
    });
    dispatch(friendsActionList(id));
    dispatch(friendsMiniStats(id));
  };

  const openNotification = (msg) => {
    notification.open({
      message: `${msg}`,
      icon: <RiCheckboxCircleFill style={{ color: "#1BE7FF" }} />,
      closeIcon: (
        <RiCloseFill className="remix-icon hp-text-color-black-80" size={24} />
      ),
    });
  };

  const handleDeleteClick = (e) => {
    let id = {
      id: e?.currentTarget?.name,
    };
    dispatch(deleteMyPost(id)).then((data) => {
      openNotification(data?.payload?.data?.message);
      dispatch(getFeeds(page));
    });
  };

  const handleChange = (e) => {
    setValue(e?.target?.value);
  };

  const [filteredFeed, setFilteredFeed] = useState();

  useEffect(() => {
    if (value === "feed") {
      setFilteredFeed(listData.filter((item) => item?.type === "feed"));
    } else if (value === "activities") {
      setFilteredFeed(listData.filter((item) => item?.type === "activities"));
    } else setFilteredFeed(listData);
  }, [value, feeds]);
  const handlePageSwitch = (pageNumber) => {
    console.log({ pageNumber });
    dispatch(getFeeds(pageNumber));
  };
  return (
    <Card className="hp-border-color-black-40">
      <Row>
        <Col className="hp-mb-16" lg={16} span={20}>
          <span className="h3 hp-font-weight-600 hp-text-color-black-bg hp-text-color-dark-0 hp-d-block">
            Health Feed
          </span>
          <div style={{ display: "flex", gap: "5px", marginTop: "3px" }}>
            <Radio.Group onChange={(e) => handleChange(e)} value={value}>
              <Radio value={"feed"}>Feed</Radio>
              <Radio value={"activities"}>Vitals</Radio>
              <Radio value={"both"}>Both</Radio>
            </Radio.Group>
          </div>
        </Col>

        <Col span={24}>
          <List
            itemLayout="vertical"
            size="large"
            pagination={false}
            dataSource={filteredFeed}
            renderItem={(item, index) => (
              <List.Item
                key={index}
                actions={[
                  <>
                    <>
                      <IconText
                        icon={
                          <Button
                            type="text"
                            name={item?.commentId}
                            htmlType="submit"
                            onClick={(e) => handleLikeClick(e)}
                            shape="circle"
                            icon={<RiHeartLine className="remix-icon" />}
                          />
                        }
                        text={item?.likes}
                        key="list-vertical-like-o"
                        style={{ gap: "none" }}
                      />
                      <IconText
                        icon={
                          <Button
                            type="text"
                            key={item?.key}
                            name={item?.commentId}
                            htmlType="submit"
                            onClick={(e) => handleCommentClick(e)}
                            shape="circle"
                            icon={<RiChat1Line className="remix-icon" />}
                          />
                        }
                        text={item?.comments}
                        key="list-vertical-message"
                      />
                      {item?.id === profile_data?.id && (
                        <>
                          <IconText
                            icon={
                              <Button
                                type="text"
                                key={item?.key}
                                name={item?.commentId}
                                htmlType="submit"
                                onClick={(e) => handleDeleteClick(e)}
                                shape="circle"
                                icon={<i className="ri-delete-bin-line" />}
                              />
                            }
                          />
                        </>
                      )}
                    </>
                    {show?.map((comment, index) =>
                      comment.id === item.commentId ? (
                        <CommentReply
                          key={index}
                          id={comment?.id}
                          showComment={
                            comment?.showComments === true ? true : false
                          }
                          userId={item?.id}
                        />
                      ) : null
                    )}
                  </>,
                ]}
                // extra={

                // }
              >
                <List.Item.Meta
                  avatar={
                    <Avatar
                      src={item?.primPhoto ? item?.primPhoto : item.avatar}
                      name={item?.id}
                      onClick={() => {
                        handlePicClick(item?.id);
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  }
                  title={
                    <a
                      onClick={() => {
                        handlePicClick(item?.id);
                      }}
                    >
                      {item?.title} <br />
                    </a>
                  }
                  description={
                    <Row style={{ display: "flex", width: "100%" }}>
                      <Col xl={16} md={16}>
                        {/* Created Date */}
                        {item?.createDate} at
                        {item?.createdDateTime}
                        <br />
                        {item?.type === "activities" ? item?.description : ""}
                      </Col>
                      <Col xl={8} md={16} span={24}>
                        {item?.type === "activities" ? (
                          item?.activityType === "bloodPressure" ? (
                            <Col
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <p
                                style={{ width: "fit-content" }}
                                className="hp-p1-body hp-mb-0"
                              >
                                <Link to="/bloodpressure">Blood Pressure</Link>
                              </p>
                              <h3
                                className="hp-mb-0 hp-font-weight-600"
                                style={{ display: "flex", gap: "5px" }}
                              >
                                <span>
                                  {item?.mm}/{item?.hg}
                                </span>
                                <p className="hp-p1-body hp-mb-0">mmHg</p>
                              </h3>
                            </Col>
                          ) : item?.activityType === "glucose" ? (
                            <Col
                              style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <p
                                style={{ width: "fit-content" }}
                                className="hp-p1-body hp-mb-0"
                              >
                                <Link to="/glucose">Glucose</Link>
                              </p>
                              <h3
                                className="hp-mb-0 hp-font-weight-600"
                                style={{ display: "flex", gap: "5px" }}
                              >
                                <span>{item?.reading}</span>
                                <p className="hp-p1-body hp-mb-0">{"mg/dL"}</p>
                              </h3>
                            </Col>
                          ) : item?.activityType === "heartRate" ? (
                            <Col
                              style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <p
                                style={{ width: "fit-content" }}
                                className="hp-p1-body hp-mb-0"
                              >
                                <Link to="/heartrate">Heart Rate</Link>
                              </p>
                              <h3
                                className="hp-mb-0 hp-font-weight-600"
                                style={{ display: "flex", gap: "5px" }}
                              >
                                <span>{item?.reading}</span>
                                <p className="hp-p1-body hp-mb-0">
                                  {item?.unit}
                                </p>
                              </h3>
                            </Col>
                          ) : item?.activityType === "o2Level" ? (
                            <Col
                              style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <p
                                style={{ width: "fit-content" }}
                                className="hp-p1-body hp-mb-0"
                              >
                                <Link to="/oxygen">Oxygen Saturation</Link>
                              </p>
                              <h3
                                className="hp-mb-0 hp-font-weight-600"
                                style={{ display: "flex", gap: "5px" }}
                              >
                                <span>{item?.oxyReading}</span>
                                <p className="hp-p1-body hp-mb-0">
                                  {item?.unit}
                                </p>
                              </h3>
                            </Col>
                          ) : item?.activityType === "sleep" ? (
                            <Col
                              style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <p
                                style={{ width: "fit-content" }}
                                className="hp-p1-body hp-mb-0"
                              >
                                <Link to="/sleep">Sleep</Link>
                              </p>
                              <h3
                                className="hp-mb-0 hp-font-weight-600"
                                style={{ display: "flex", gap: "5px" }}
                              >
                                <span>{item?.reading}</span>
                                <p className="hp-p1-body hp-mb-0">
                                  {item?.unit}
                                </p>
                              </h3>
                            </Col>
                          ) : item?.activityType === "Weight" ? (
                            <Col
                              style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <p
                                style={{ width: "fit-content" }}
                                className="hp-p1-body hp-mb-0"
                              >
                                <Link to="/weight">Weight</Link>
                              </p>
                              <h3
                                className="hp-mb-0 hp-font-weight-600"
                                style={{ display: "flex", gap: "5px" }}
                              >
                                <span>{item?.reading}</span>
                                <p className="hp-p1-body hp-mb-0">
                                  {item?.unit}
                                </p>
                              </h3>
                            </Col>
                          ) : null
                        ) : null}

                        {item?.assets ? (
                          <img
                            width={150}
                            height={150}
                            style={{ aspectRatio: "3/2", objectFit: "contain" }}
                            alt="logo"
                            src={item?.assets}
                          />
                        ) : null}
                      </Col>
                    </Row>
                  }
                />
                <p style={{ overflowWrap: "break-word", maxWidth: "30rem" }}>
                  {item.content}
                </p>
              </List.Item>
            )}
          />
        </Col>
        <Col span={24}>
          <Pagination
            current={page}
            onChange={handlePageSwitch}
            total={totalPosts}
            showSizeChanger={false}
            pageSize={pageSize}
          />
        </Col>
      </Row>

      {checkedCode && (
        <SyntaxHighlighter
          language="javascript"
          className={`show-code hp-mt-24 ${codeClass && "show-code-active"}`}
          style={monoBlue}
        >
          {vertical}
        </SyntaxHighlighter>
      )}
    </Card>
  );
}
