import React, { Suspense } from "react";

import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

import { Provider } from "react-redux";
// import store, { persistor } from './redux/store';
import store, { persistor } from "./redux-toolkit/store";

import "./assets/icons/remixicon.css";
import "./assets/less/yoda-theme.less";
import "./assets/style/custom.css";
import App from "./App";

ReactDOM.render(
  <Suspense fallback="loading">
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </Suspense>,
  document.getElementById("root")
);
